import { Route } from '@core/constants/routes'
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'

import type { Customer, User } from './main-types'

export interface RouteMatch {
  path: Route
  url: string
  params: Record<string, any>
}

export interface State {
  user: User | null
  customer: Customer | null
  routeMatch: RouteMatch | null
  downloadNotification: boolean
}

export function getInitialState(): State {
  return {
    customer: null,
    user: null,
    routeMatch: null,
    downloadNotification: false,
  }
}

export const routeEnter = createAction<RouteMatch>('main/routeEnter')
export const routeLeave = createAction<RouteMatch>('main/routeLeave')

export const slice = createSlice({
  name: 'main',
  initialState: getInitialState(),
  reducers: {
    set(state: State, action: PayloadAction<Partial<State>>) {
      Object.assign(state, action.payload)
    },

    reset(state: State) {
      Object.assign(state, getInitialState())
    },
  },
  extraReducers: (builder) => {
    builder.addCase(routeEnter, (state, action) => {
      state.routeMatch = action.payload
    })
  },
})

export default slice

export const actions = {
  ...slice.actions,
  routeEnter,
  routeLeave,
}
