import _ from 'lodash'

import type { ContentVersion, Item } from '@pages/project-details/project-details-types'

/**
 * Round number to one decimal place
 * @param {*} num - Number
 */
export const toOneDecimalPlace = (num: number) => parseFloat(num.toFixed(1))

/**
 * Round number to two decimal place
 * @param {*} num - Number
 */
export const toTwoDecimalPlace = (num: number) => parseFloat(num.toFixed(2))

/**
 * Returns an object with domain tags items count
 * @param {*} items - Array
 */
export const getJobDomainTagsCount = (items: Item[]) => {
  return items.reduce(
    (prev, item) => {
      const { domain } = item.aiModel

      if (!domain) return prev

      const prevTags = prev

      if (prevTags[domain]) prevTags[domain] += 1
      else prevTags[domain] = 1

      return prevTags
    },
    {} as Record<string, number>,
  )
}

/**
 * Returns an object with items clone count
 * @param {*} items - Array
 */
export const getItemsCloneCount = (items: Item[]) =>
  items
    .reduce((sum, { clonedFromId, clones }) => {
      if (clonedFromId || clones.aggregate.count) return sum + 1
      return sum
    }, 0)
    .toString()

/**
 * Returns an object with items clone count
 * @param {*} items - Array
 */
export const getItemsInternalCount = (items: Item[]) =>
  items.reduce((sum, { aiModel }) => {
    if (aiModel.isInternal) return sum + 1
    return sum
  }, 0)

/**
 * Returns an object with items topics count
 * @param {*} items - Array
 */
export const getItemsTopicsCount = (items: Item[]): Record<string, number> =>
  items.reduce((prev, item) => {
    const contentVersion = item.savedContent[0] || item.currentContent[0]
    if (!contentVersion) return prev

    const itemTopic = contentVersion?.submodels[0] || contentVersion.content.topic

    const prevTopics = prev

    if (prevTopics[itemTopic]) prevTopics[itemTopic] += 1
    else prevTopics[itemTopic] = 1

    return prevTopics
  }, {})

/**
 * Returns true when there are comments
 */
export const hasComments = (comments: ContentVersion['comments']) => {
  return comments.filter((comment) => comment.value).length > 0
}

/**
 * Returns string with [a-zA-Z] letters
 * @param {*} str - String
 */
export const extractAlphabet = (str) => str?.replace(/[^a-zA-Z]/g, '')

/**
 * Returns specific string with [a-zA-Z] letters by position (start, end)
 * @param {*} str - String
 * @param {*} start - Number
 * @param {*} end - Number
 */
export const extractAlphabetByPosition = (str, start, end) => {
  return extractAlphabet(str)?.substring(start, end)
}

/**
 * Create a date and subtract the number of seconds
 * @param {number} seconds
 * @returns ISO 8601 date string
 */
export const getDateAgo = (seconds = 240 * 60) => {
  const date = new Date()
  date.setSeconds(date.getSeconds() - seconds)

  return date.toISOString()
}

export const getRemoveItemsVars = (selectedItems: Item[]) => {
  const itemIds = selectedItems.map((item) => item.id)
  const jobs = _.groupBy(selectedItems, 'job.id')

  const jobUpdates = Object.entries(jobs).map(([id, items]) => {
    const itemsPassed = items[0].job.itemsPassed - items.length
    const jobStatus = itemsPassed >= items[0].job.itemsRequired ? 'DONE' : 'IN_PROGRESS'

    return {
      where: { id: { _eq: id } },
      _set: { items_passed: itemsPassed, status: jobStatus },
    }
  })

  return {
    ids: itemIds,
    job_updates: jobUpdates,
  }
}
