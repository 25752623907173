export const common = { black: '#000000', white: '#ffffff' }

export const background = {
  button: 'rgba(235, 236, 240, 0.6)',
  white: common.white,
  grey: '#fafbfc',
  paleGrey: '#f9f7f9',
  blueGrey: '#f4f5f7',
  progress: '#d5d7de',
  notification: '#4c9aff',
  successGreen: '#E3FCEF',
  hrLight: '#d8d8d8',
  balanceGreen: '#e2ffee',
  help2: '#5243aa',
  pattensBlue: '#deebff',
  itemVoteButton: 'rgba(33, 150, 243, 0.08)',
  vector: '#dfe1e5',
  orange: '#ff5630',
  textArea: '#f9f9f9',
  disabledCard: '#f4f5f7',
  disabledButton: '#f4f5f7',
  purple: '#6554c0',
}

export const linkBlue = '#0052cc'

export const text = {
  cadetBlue: '#a5adba',
  slate: '#505f79',
  slateBlue: '#172b4d',
  lightSlateGray: '#7a869a',
  indigo: '#253858',
  greyBlue: '#6b778c',
  greyDarkerBlue: '#56667d',
  silver: '#8993a4',
  blue: '#0065ff',
  grayishBlue: '#5e6c84',
  balanceGreen: '#006644',
  peacockBlue: '#0747a6',
  milanoRed: '#bf2600',
  red: '#de350b',
  disabled: '#00000042',
  linkBlue,
}

const actualWords = '#C0B6F2'
const mariGold = '#ffc400'
const paragraphs = '#d4066e'
const seafoamBlue = '#57d9a3'
const coleman = '#ff7452'
const aquaBlue = '#00c7e6'
const easy = '#ABF5D1'
const medium = '#FFF0B3'
const hard = '#FFBDAD'
const mediumDark = '#FF8B00'

export const colors = {
  B050: '#deebff',
  B075: '#b3d4ff',
  B100: '#4c9aff',
  B200: '#2684ff',
  B300: '#0065ff',
  B400: '#0052cc',
  B500: '#0747a6',

  G050: '#e3fcef',
  G075: '#abf5d1',
  G100: '#79f2c0',
  G200: '#57d9a3',
  G300: '#36b37e',
  G400: '#00875a',
  G500: '#006644',

  N000: '#ffffff',
  N010: '#f4f5f7',
  N020: '#fafbfc',
  N030: '#ebecf0',
  N040: '#dfe1e5',
  N050: '#c1c7d0',
  N060: '#b3bac5',
  N070: '#a5adba',
  N080: '#97a0af',
  N090: '#8993a4',
  N100: '#7a869a',
  N200: '#6b778c',
  N300: '#5e6c84',
  N400: '#505f79',
  N500: '#42526e',
  N600: '#344563',
  N700: '#253858',
  N800: '#172b4d',
  N900: '#091e42',
  N1000: '#000000',

  P050: '#eae6ff',
  P075: '#c0b6f2',
  P100: '#998dd9',
  P200: '#8777d9',
  P300: '#6554c0',
  P400: '#5243aa',
  P500: '#403294',

  R050: '#ffebe5',
  R075: '#ffbdad',
  R100: '#ff8f73',
  R200: '#ff7452',
  R300: '#ff5630',
  R400: '#de350b',
  R500: '#bf2600',

  T050: '#e6fcff',
  T075: '#b3f5ff',
  T100: '#79e2f2',
  T200: '#00c7e6',
  T300: '#00b8d9',
  T400: '#00a3bf',
  T500: '#008da6',

  Y050: '#fffae6',
  Y075: '#fff0b3',
  Y100: '#ffe380',
  Y200: '#ffc400',
  Y300: '#ffab00',
  Y400: '#ff991f',
  Y500: '#ff8b00',
}

export const itemSetColors = {
  ITEM_SET_B300: {
    main: colors.B300,
    contrastText: colors.N000,
  },
  ITEM_SET_Y500: {
    main: colors.Y500,
    contrastText: colors.N800,
  },
  ITEM_SET_P075: {
    main: colors.P075,
    contrastText: colors.N800,
  },
  ITEM_SET_R500: {
    main: colors.R500,
    contrastText: colors.N000,
  },
  ITEM_SET_G075: {
    main: colors.G075,
    contrastText: colors.N800,
  },
  ITEM_SET_T300: {
    main: colors.T300,
    contrastText: colors.N800,
  },
  ITEM_SET_R075: {
    main: colors.R075,
    contrastText: colors.N800,
  },
  ITEM_SET_G500: {
    main: colors.G500,
    contrastText: colors.N000,
  },
  ITEM_SET_B075: {
    main: colors.B075,
    contrastText: colors.N800,
  },
  ITEM_SET_Y075: {
    main: colors.Y075,
    contrastText: colors.N800,
  },
  ITEM_SET_P300: {
    main: colors.P300,
    contrastText: colors.N000,
  },
  ITEM_SET_T075: {
    main: colors.T075,
    contrastText: colors.N800,
  },
}

export const tag = {
  warning: { main: '#FFE380', contrastText: text.slateBlue },
  ametist: { main: '#9b59b6', contrastText: common.white },
  peterRiver: { main: '#3498db', contrastText: common.white },
  dusk: { main: '#42526e', contrastText: common.white },
  batch: { main: '#B3D4FF', contrastText: linkBlue },
  aquaBlue: { main: aquaBlue, contrastText: text.slateBlue },
  seafoamBlue: { main: seafoamBlue, contrastText: text.slateBlue },
  mariGold: { main: mariGold, contrastText: text.slateBlue },
  gradeLevelBlue: { main: '#74b9ff', contrastText: text.slateBlue },
  pinkRed: { main: '#f50057', contrastText: common.white },
  actualWords: { main: actualWords, contrastText: text.slateBlue },
  paragraphs: { main: paragraphs, contrastText: common.white },
  coleman: { main: coleman, contrastText: text.slateBlue },
  easy: { main: easy, avatarBg: text.balanceGreen, contrastText: text.slateBlue },
  medium: { main: medium, avatarBg: mediumDark, contrastText: text.slateBlue },
  hard: { main: hard, avatarBg: text.milanoRed, contrastText: text.slateBlue },
  clone: { main: background.purple, contrastText: common.white },
  STORY: { main: '#1E3A60', contrastText: common.white },
  MCQ: { main: text.blue, contrastText: common.white },
  HOT_TEXT: { main: text.blue, contrastText: common.white },
  FRQ: { main: '#79E2F2', contrastText: text.slateBlue },
  MPFRQ: { main: '#FFAB00', contrastText: text.slateBlue },
  ITEM_SET: { main: '#40739e', contrastText: common.white },
  ITEM_SET2: { main: '#34495e', contrastText: common.white },
  PASSAGE_BUILDER: { main: '#1E3A60', contrastText: common.white },
  LIKERT: { main: '#56667d', contrastText: common.white },

  ITEM_SET_BLACK_WHITE: { main: colors.N010, contrastText: colors.N900 },

  ...itemSetColors,
}

export const histogram = {
  colors: {
    'actual-words': actualWords,
    'word-count': actualWords,
    'number-of-sentences': mariGold,
    'type-token-ratio': mariGold,
    'number-of-paragraphs': paragraphs,
    'flesch-reading-ease': paragraphs,
    'flesch-kincaid-readability': seafoamBlue,
    'coleman-liau-index': coleman,
    'type-to-token-ratio': aquaBlue,
  },
  additionalColors: [actualWords, mariGold, paragraphs, seafoamBlue, coleman, aquaBlue],
  defaultColor: '#87b4df',
}

export const fgProgress = '#006EFE'

export const other = {
  hrLight: '#d8d8d8',
  scandalGreen: '#e3fcef',
  checkedGreen: '#00875a',
  ghostBlue: '#c1c7d0',
  fairPink: '#ffebe5',
  lightBlue: '#eef7fe',
  downriver: '#091e42',
  sliderDisabled: '#ebecf0',
  grayishBlueDark: '#4A5568',
}

export const suggestAnswer = {
  distractor: '#ffebe5  ',
  correct: '#e3fcef',
}

export const states = {
  success: '#36b37e',
  error: '#de350b',
  warning: '#ffab00',
  focus: '#4c9aff',
}

export const biasCheckStates = {
  PASSED: '#36b37e',
  FAILED: '#ff5630',
  SKIPPED: '#36b37e',
  EDITED: '#ffab00',
  NOT_STARTED: '#c1c7d0',
}

export const keyCheckStateColors = {
  DONE: '#36b37e',
  FAILED: '#ff5630',
  EDITED: '#ffab00',
  IN_PROGRESS: '#ffab00',
}
