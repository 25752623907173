import './init'

import React from 'react'
import { createRoot } from 'react-dom/client'

import 'symbol-observable'

import env from '@core/env'

import App from './app'

const container = document.getElementById('app-container')
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)

root.render(<App />)

async function loadAxe() {
  const axe = await import('@axe-core/react')

  axe.default(React, root, 1000, {
    runOnly: {
      // @ts-ignore
      type: 'tag',
      values: ['wcag2a', 'wcag2aa'],
    },
  })
}

if (['local', 'dev', 'qa'].includes(env.ENV as string)) {
  loadAxe()
}
