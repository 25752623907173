import React from 'react'
import {
  FormControlLabel,
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
} from '@mui/material'
import { Controller } from 'react-hook-form'

type CheckboxProps = {
  name: string
  label: string
  control: any
  labelPlacement?: 'start' | 'top' | 'end' | 'bottom'
  disabled?: boolean
  className?: string
} & Pick<MuiCheckboxProps, 'size' | 'color'>

export const Checkbox = ({
  name,
  label,
  control,
  labelPlacement,
  disabled,
  className,
  size = 'small',
  color = 'secondary',
}: CheckboxProps) => {
  return (
    <FormControlLabel
      label={label}
      disabled={disabled}
      slotProps={{ typography: { fontSize: 14 } }}
      labelPlacement={labelPlacement}
      className={className}
      control={
        <Controller
          name={name}
          control={control}
          render={({ field: { value, ...field } }) => (
            <MuiCheckbox {...field} size={size} checked={!!value} color={color} />
          )}
        />
      }
    />
  )
}

export default Checkbox
