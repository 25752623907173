import React from 'react'
import { useLocation } from 'react-router-dom'

import { DELIVER_ROUTE } from '@core/constants/routes'
import { useAppDispatch } from '@core/store'

import { actions } from './deliver-slice'

export enum FilterType {
  all = 'all',
  clone = 'clone',
  internal = 'internal',
}

export type Filters = {
  q: string
  sort: string
  order: 'asc' | 'desc'
  user: string[]

  type: FilterType
  domain: string[]
  topic: string[]
}

export const defaultFilters: Filters = {
  q: '',
  sort: 'name',
  order: 'asc',
  user: [],
  type: FilterType.all,
  domain: [],
  topic: [],
}

export const getURL = (filters: Partial<Filters>) => {
  const params = new URLSearchParams()

  for (const [key, value] of Object.entries(filters)) {
    if (value && value !== defaultFilters[key]) {
      if (Array.isArray(value)) {
        value.forEach((v) => params.append(key, v))
        continue
      }
      params.set(key, value)
    }
  }

  return `${DELIVER_ROUTE}?${params.toString()}`
}

export const useWatchFilters = () => {
  const dispatch = useAppDispatch()
  const location = useLocation()

  React.useEffect(() => {
    const query = new URLSearchParams(location.search)

    dispatch(
      actions.setFilters({
        q: query.get('q') || '',
        sort: query.get('sort') || 'name',
        order: query.get('order') === 'desc' ? 'desc' : 'asc',
        user: query.getAll('user') || [],
        type: (query.get('type') as FilterType) || FilterType.all,
        domain: query.getAll('domain') || [],
        topic: query.getAll('topic') || [],
      })
    )
  }, [location.search])

  return null
}
