import React from 'react'
import {
  Box,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  type StyleProps,
} from '@mui/material'

import { useQuery } from '@tanstack/react-query'

import * as exportAPI from './export-api'
import { useExportJobs } from './export-items-state'
import { ExportHeader } from './export-result-common'
import { getErrorMessage } from './export-utils'

const styles = {
  tabTitle: {
    typography: 'h5',
    mt: 2,
    mb: 4,
    px: 3,
  },
  table: {
    my: 2,
    '& td:first-of-type, & th:first-of-type': {
      pl: 3,
    },
    '& tbody td': {
      borderBottom: 0,
    },
    '& tbody tr:last-of-type td': {
      borderBottom: '1px solid #f6f7f9',
    },
    '& tbody tr:nth-of-type(odd)': {
      bgcolor: '#f9f9fa',
    },
  },
} satisfies StyleProps

const IssuesTab = () => {
  const [selectedJobId] = useExportJobs((state) => [state.exportDialog.selectedJobId])
  const exportDetails = useQuery({
    queryKey: exportAPI.exportItemsKeys.details(selectedJobId!),
    queryFn: () => exportAPI.getExportDetails(selectedJobId!),
    enabled: !!selectedJobId,
  })
  const exportJob = exportDetails.data?.exportJob

  if (exportDetails.isPending) {
    return (
      <>
        <Box sx={styles.tabTitle}>Issues</Box>

        <Box mx={3}>Loading...</Box>
      </>
    )
  }

  if (exportDetails.error) {
    return (
      <>
        <Box sx={styles.tabTitle}>Issues</Box>

        <Box mx={3}>Failed to load issues</Box>
      </>
    )
  }

  if (!exportJob?.failedItems.length) {
    return (
      <>
        <Box sx={styles.tabTitle}>Issues</Box>

        <Box mx={3}>No issues found</Box>
      </>
    )
  }

  return (
    <>
      <Box sx={styles.tabTitle}>Issues</Box>
      <Box ml={3}>
        <ExportHeader job={exportJob} />
      </Box>
      <Table sx={styles.table}>
        <TableHead>
          <TableRow>
            <TableCell>Identifier</TableCell>
            <TableCell>Title</TableCell>
            <TableCell>Issue</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {exportJob?.failedItems.map((row) => (
            <TableRow key={row.item.id}>
              <TableCell width={100} sx={{ fontWeight: 'bold', maxWidth: 100 }}>
                {row.item.identifier}
              </TableCell>
              <TableCell sx={{ maxWidth: 400 }}>
                <Typography noWrap>
                  {row.errorDetails.code === 'ProBuilderMappingException' &&
                  row.errorDetails.can_fix ? (
                    <Link
                      color="secondary"
                      underline="hover"
                      href={`/author/item/${row.item.id}`}
                      target="_blank"
                    >
                      {row.item.savedContent[0]?.question}
                    </Link>
                  ) : (
                    row.item.savedContent[0]?.question
                  )}
                </Typography>
              </TableCell>
              <TableCell width={300} sx={{ maxWidth: 300 }}>
                {getErrorMessage(row.errorDetails).message || 'Unknown error'}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  )
}

export default IssuesTab
