import { useMemo } from 'react'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { useApolloClient } from '@apollo/client'
import * as comments from '@containers/comments/comments-slice'
import * as main from '@containers/main/main-slice'
import * as metadata from '@containers/metadata/metadata-slice'
import * as authorCloze from '@pages/author-cloze/author-cloze-slice'
import * as author from '@pages/author/author-slice'
import * as deliver from '@pages/deliver/deliver-slice'
import * as users from '@pages/users/users-slice'
import {
  AsyncThunk,
  AsyncThunkOptions,
  AsyncThunkPayloadCreator,
  configureStore,
} from '@reduxjs/toolkit'

import { DeepPartial } from './types'

export const reducer = {
  main: main.slice.reducer,
  author: author.slice.reducer,
  authorCloze: authorCloze.slice.reducer,
  users: users.slice.reducer,
  deliver: deliver.slice.reducer,
  comments: comments.slice.reducer,
  metadata: metadata.slice.reducer,
}

export function createStore(
  navigate: ReturnType<typeof useNavigate>,
  client: ReturnType<typeof useApolloClient>,
  preloadedState = {},
) {
  return configureStore({
    reducer,
    devTools: true,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: { navigate, client },
        },
        serializableCheck: false,
      }),
  })
}

export function useStore(initialState = {}) {
  const navigate = useNavigate()
  const client = useApolloClient()

  // never should rebuild the store, so empty array
  return useMemo(() => createStore(navigate, client, initialState), [])
}

export type Store = ReturnType<typeof useStore>
export type AppDispatch = ReturnType<typeof createStore>['dispatch']
export type RootState = ReturnType<ReturnType<typeof createStore>['getState']>
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

// used to build the tests initial state shape
export const createStoreState = (preloadedState: DeepPartial<RootState> = {}) => {
  return configureStore({
    reducer,
    // @ts-expect-error
    preloadedState,
  }).getState()
}

declare module '@reduxjs/toolkit' {
  type AsyncThunkConfig = {
    state?: unknown
    dispatch?: AppDispatch
    extra?: { navigate: any; client: any }
    rejectValue?: unknown
    serializedErrorType?: unknown
  }

  function createAsyncThunk<
    Returned,
    ThunkArg = void,
    ThunkApiConfig extends AsyncThunkConfig = {
      state: RootState
      dispatch: AppDispatch
      extra: {
        navigate: ReturnType<typeof useNavigate>
        client: ReturnType<typeof useApolloClient>
      }
    },
  >(
    typePrefix: string,
    payloadCreator: AsyncThunkPayloadCreator<Returned, ThunkArg, ThunkApiConfig>,
    options?: AsyncThunkOptions<ThunkArg, ThunkApiConfig>,
  ): AsyncThunk<Returned, ThunkArg, ThunkApiConfig>
}
