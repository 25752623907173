import React from 'react'
import { Alert, Box, Checkbox, FormControlLabel, Snackbar } from '@mui/material'

import * as sessionStorage from '@core/utils/session-storage'

import { useSnackState } from './snack-state'

// It only render one snackbar at a time. It automatically opens the next one, if available.
export const Snacks = () => {
  const { items, remove, openKey } = useSnackState()

  const handleClose = (key: string) => {
    remove(key)
  }

  const handleChange = (key: string, checkboxKey: string | undefined) => {
    if (checkboxKey) {
      sessionStorage.set(checkboxKey, true)
    }
    remove(key)
  }

  return (
    <>
      {Object.values(items).map((item) => {
        return (
          <Snackbar
            key={item.key}
            open={item.key === openKey}
            onClose={() => handleClose(item.key)}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <Alert onClose={() => handleClose(item.key)} severity={item.severity}>
              {item.message}
              {item.checkboxKey && (
                <Box>
                  <FormControlLabel
                    disableTypography
                    control={
                      <Checkbox
                        size="small"
                        sx={{ ml: 1.5 }}
                        onChange={() => handleChange(item.key, item.checkboxKey)}
                      />
                    }
                    label="Don't show me this again"
                  />
                </Box>
              )}
            </Alert>
          </Snackbar>
        )
      })}
    </>
  )
}
