import { ExternalMetadata, ExternalMetadataSetting } from '@containers/metadata/metadata-types'
import { CLOZE, ITEM_SET, MCQ } from '@core/constants/content-type'

export enum AnswerStatus {
  key = 'CORRECT',
  distractor = 'DISTRACTOR',
  excluded = 'EXCLUDED',
}
export interface Answer {
  value: string
  status: AnswerStatus | null
}
export interface ContentCloze {
  options?: Answer[]
  question?: string | undefined
  stimulus?: string | undefined
}
export interface ContentMCQ {
  question?: string | undefined
  answers?: Answer[]
  stimulus?: string | undefined
}

export interface Comment {
  id: string
  name: string
  value: string
}
export type Comments = Record<string, Comment>

export interface ContentRoot {
  custom_passage: string
  stimulus?: string
  cloze_ids?: string[]
  mcq_ids?: string[]
}

interface ContentFilter {
  label: string
}

export type Content = ContentCloze | ContentMCQ

export type RawQualityMetrics = { name: string; value: number }[]

export interface BaseItem {
  id: string
  step: string
  status: string
  index?: number
  contentFilter?: ContentFilter
  aiModelId?: string
}

export interface ItemCloze extends BaseItem {
  type: typeof CLOZE
  currentContent: {
    content: ContentCloze | null
    submodels: string[]
  }
  savedContent?: {
    content: ContentCloze | null
    submodels: string[]
  }
  originalContent: {
    submodels: string[]
  }
}

export interface ItemMCQ extends BaseItem {
  type: typeof MCQ
  currentContent: {
    content: ContentMCQ | null
    submodels: string[]
  }
  savedContent?: {
    content: ContentMCQ | null
    submodels: string[]
  }
  originalContent?: {
    submodels: string[]
  }
}

export type Item = ItemCloze | ItemMCQ

export interface ItemRoot extends Omit<BaseItem, 'savedContent' | 'currentContent'> {
  job: {
    id: string
    ownerId: string
    selectedSubmodels: string[]
  }
  aiModel: {
    name: string
    flavors: any
    externalMetadataSettings: ExternalMetadataSetting[] | null
    type: string
  }
  type: typeof ITEM_SET
  project: {
    id: string
    name: string
    access: { write: boolean }[]
  } | null

  savedContent?: {
    content?: ContentRoot | null
    externalMetadata?: ExternalMetadata | null
    submodels: string[] | null
    comments?: Comment[]
  }

  currentContent: {
    content: ContentRoot
    externalMetadata: ExternalMetadata | null
    qualityMetrics
    comments?: Comment[]
    submodels: string[]
  }

  originalContent: {
    submodels: string[]
  }
}

export type ItemList = (Item | ItemRoot)[]

export type ItemMap = { root: ItemRoot } & Record<string, ItemMCQ | ItemCloze>

export interface State {
  initialized: boolean
  contentTab: number
  sidebarTab: number
  notFound: boolean
  items: ItemMap | null
  expanded: string | null
  savingDraft: boolean
  savingItem: boolean
  match: string | null
  key: number
  fatalError?: string
}

export interface StateLoaded extends State {
  step: 'loaded'
  items: ItemMap
  initialized: true
}

export type RawComment = Omit<Comment, 'id'> & { id?: string }

export type RawRootContentVersion = {
  content: {
    mcq_ids: string[]
    cloze_ids: string[]
    custom_passage: string
    stimulus: string
  }
  comments: RawComment[]
  submodels: string[]
  qualityMetrics: RawQualityMetrics
  external_metadata: ExternalMetadata
}

type Rationale = {
  id: string
  value: string
}
export type RawChildContentVersion = {
  comments: RawComment[]
  submodels: string[]
  qualityMetrics: RawQualityMetrics
  content: {
    question: string
    stem: string
    answers?: {
      id?: string
      value: string
      status: AnswerStatus
      rationales?: Rationale[]
    }[]
    options?: Answer[]
    selected?: boolean
  }
}

export type RawItem = {
  currentContent: [RawChildContentVersion]
  savedContent: [RawChildContentVersion] | []
  originalContent: [RawChildContentVersion] | []
} & Omit<Item, 'lastContent' | 'finalContent'>

export type RawItemSet = {
  currentContent: [RawRootContentVersion]
  savedContent: [RawRootContentVersion] | []
  originalContent: [RawRootContentVersion] | []
  children: RawItem[]
} & Omit<ItemRoot, 'lastContent' | 'finalContent'>
