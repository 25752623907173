import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'

import type { Project, State } from '@pages/deliver/deliver-types'
import { routeLeave } from '@containers/main/main-slice'

import { defaultFilters } from './deliver-filters'
import * as thunk from './deliver-thunk'

export const thunkActions = thunk

export function getInitialState(): State {
  return {
    filters: defaultFilters,
    draftItemsLength: 0,
    projects: null,
    domainTags: {
      tags: [],
      topics: [],
      hasClones: false,
      hasInternal: false,
    },
    loading: true,
    selectedProjects: [],
  }
}

export const slice = createSlice({
  name: 'deliver',
  initialState: getInitialState(),
  reducers: {
    set(state: State, action: PayloadAction<Partial<State>>) {
      Object.assign(state, action.payload)
    },
    setFilters(state, action: PayloadAction<Partial<State['filters']>>) {
      state.filters = { ...state.filters, ...action.payload }
    },
    selectProject(state: State, action: PayloadAction<{ project: Project }>) {
      const { project } = action.payload
      const index = state.selectedProjects.findIndex((p) => p.id === project.id)
      if (index > -1) {
        state.selectedProjects.splice(index, 1)
      } else {
        state.selectedProjects.push(project)
      }
    },
    selectProjects(state: State, action: PayloadAction<{ projects: Project[]; add: boolean }>) {
      if (!action.payload.add) {
        state.selectedProjects = _.differenceBy(
          state.selectedProjects,
          action.payload.projects,
          'id'
        )
      } else {
        state.selectedProjects = _.union(state.selectedProjects, action.payload.projects)
      }
    },
    deleteProject(state: State, action: PayloadAction<{ id: string }>) {
      const selectedIndex = state.selectedProjects.findIndex((p) => p.id === action.payload.id)

      if (selectedIndex > -1) {
        state.selectedProjects.splice(selectedIndex, 1)
      }

      const projectIndex = state.projects!.findIndex((p) => p.id === action.payload.id)

      if (projectIndex > -1) {
        state.projects!.splice(projectIndex, 1)
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunkActions.fetchProjects.fulfilled, (state: State, action) => {
        Object.assign(state, { ...action.payload, loading: false })
      })
      .addCase(
        thunkActions.fetchDrafts.fulfilled,
        (state: State, action: PayloadAction<number>) => {
          state.draftItemsLength = action.payload
        }
      )
      .addCase(routeLeave, (state, action) => {
        if (action.payload.path.includes('/deliver')) {
          state.selectedProjects = getInitialState().selectedProjects
        }
      })
  },
})

export const { actions } = slice
