import { gql, TypedDocumentNode } from '@apollo/client'

export const EXPORT_ITEMS: TypedDocumentNode<
  { export_items: { job_id: string } },
  { itemIds?: string[]; projectIds?: string[]; exportType: string; fileName?: string }
> = gql`
  mutation export_items(
    $itemIds: [uuid!]
    $projectIds: [uuid!]
    $exportType: String!
    $fileName: String
  ) {
    export_items(
      item_ids: $itemIds
      project_ids: $projectIds
      export_type: $exportType
      file_name: $fileName
    ) {
      job_id
    }
  }
`

export const DOWNLOAD_ITEMS = gql`
  mutation download_items($export_job_id: uuid!) {
    download_items(export_job_id: $export_job_id) {
      url
    }
  }
`

export type ErrorDetailsBase = {
  code?: string
  message?: string
  created_at?: string
}

export type ErrorDetails = ErrorDetailsBase &
  (
    | {
        code: 'ProBuilderCreateItemException'
        error?: {
          ErrorCode: number
          Message: string
        }
      }
    | {
        code: 'ProBuilderInvalidSubjectException'
      }
    | {
        code: 'InvalidIntegrationCredentialsException'
      }
    | {
        code: 'ProBuilderMappingException'
        reason: string
        can_fix: boolean
        skipped?: boolean
      }
  )

export type ExportJob = {
  id: string
  status:
    | 'pending'
    | 'in_progress'
    | 'completed'
    | 'failed'
    | 'completed_with_issues'
    | 'waiting_user_input'
  createdAt: string
  fileName: string
  exportType: string
  totalFailed: number
  totalCompleted: number
  totalItems: number
  errorDetails: ErrorDetails
}

export const EXPORT_JOB_STATUS: TypedDocumentNode<{ exportJobs: ExportJob[] }, { ids: string[] }> =
  gql`
    subscription status($ids: [uuid!]!) {
      exportJobs: export_items_job(where: { id: { _in: $ids } }) {
        id
        status
        fileName: file_name
        exportType: export_type
        totalFailed: total_failed
        totalCompleted: total_completed
        totalItems: total_items
        errorDetails: error_details
      }
    }
  `

type ExportJobData = {
  destination_url?: string
}

type ExportItemData = {
  item_created?: {
    id: string
    href: string
  }
}

export type ExportItem = {
  status: string
  errorDetails: ErrorDetails
  data: ExportItemData
  item: {
    id: string
    identifier: string
    contentType: string
    savedContent: [{ question: string }]
  }
}

export type ExportJobDetails = ExportJob & {
  data?: ExportJobData
  failedItems: ExportItem[]
  isArchived: boolean
  errorDetails: ErrorDetails
}

export const EXPORT_RESULT_QUERY: TypedDocumentNode<
  {
    exportJob: ExportJobDetails
  },
  { exportJobId: string }
> = gql`
  query export_result_query($exportJobId: uuid!) {
    exportJob: export_items_job_by_pk(id: $exportJobId) {
      id
      status
      fileName: file_name
      createdAt: created_at
      exportType: export_type
      totalFailed: total_failed
      totalCompleted: total_completed
      totalItems: total_items
      errorDetails: error_details
      isArchived: is_archived
      data
      failedItems: items(
        where: { status: { _in: ["failed", "completed_with_issues", "waiting_user_input"] } }
      ) {
        status
        errorDetails: error_details
        data
        item {
          id
          identifier
          contentType: content_type
          savedContent: content_version(args: { content_type: "saved" }) {
            question: content(path: "question")
          }
        }
      }
    }
  }
`

export const EXPORT_RESULT: TypedDocumentNode<
  {
    exportJob: ExportJobDetails
  },
  { exportJobId: string }
> = gql`
  subscription export_result_subscription($exportJobId: uuid!) {
    exportJob: export_items_job_by_pk(id: $exportJobId) {
      id
      status
      fileName: file_name
      createdAt: created_at
      exportType: export_type
      totalFailed: total_failed
      totalCompleted: total_completed
      totalItems: total_items
      errorDetails: error_details
      isArchived: is_archived
      data
      failedItems: items(
        where: { status: { _in: ["failed", "completed_with_issues", "waiting_user_input"] } }
      ) {
        status
        errorDetails: error_details
        data
        item {
          id
          identifier
          contentType: content_type
          savedContent: content_version(args: { content_type: "saved" }) {
            question: content(path: "question")
          }
        }
      }
    }
  }
`

export type ExportJobBasic = Pick<
  ExportJob,
  'id' | 'status' | 'createdAt' | 'fileName' | 'exportType'
>

export const GET_LATEST_EXPORT_JOBS: TypedDocumentNode<
  { exportJobs: ExportJobBasic[]; agg: { aggregate: { count: number } } },
  { exportType: string; userId: string }
> = gql`
  query get_latest_export_jobs($exportType: String!, $userId: String!) {
    exportJobs: export_items_job(
      order_by: { created_at: desc }
      where: {
        export_type: { _eq: $exportType }
        user_id: { _eq: $userId }
        is_archived: { _eq: false }
      }
    ) {
      id
      status
      createdAt: created_at
      fileName: file_name
      exportType: export_type
    }

    agg: export_items_job_aggregate(
      where: {
        export_type: { _eq: $exportType }
        user_id: { _eq: $userId }
        is_archived: { _eq: false }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`

export type ExportContinueMode = 'retry' | 'skip' | 'cancel'

export const CONTINUE_EXPORT: TypedDocumentNode<
  { continue_export_job: { job_id: string } },
  { exportJobId: string; mode: ExportContinueMode }
> = gql`
  mutation continue_export_job($exportJobId: uuid!, $mode: String!) {
    continue_export_job(export_job_id: $exportJobId, export_continue_mode: $mode) {
      job_id
    }
  }
`

export const ARCHIVE_EXPORT_JOBS: TypedDocumentNode<
  { update_export_items_job: { affected_rows: number } },
  { ids: string[] }
> = gql`
  mutation archive_export_jobs($ids: [uuid!]!) {
    update_export_items_job(where: { id: { _in: $ids } }, _set: { is_archived: true }) {
      affected_rows
    }
  }
`
