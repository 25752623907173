import React from 'react'
import FolderOpenIcon from '@mui/icons-material/FolderOpen'
import { Box, Typography } from '@mui/material'

type ProjectDetailsProps = {
  project?: {
    name: string
    owner?: {
      email: string
    }
  } | null
}

export const ProjectDetails = (props: ProjectDetailsProps) => {
  if (!props.project) return null

  return (
    <Box typography="h5" display="flex" alignItems="center" my={2}>
      <Box>
        <FolderOpenIcon sx={{ mr: 1 }} />
      </Box>
      <Box minWidth={0}>
        <Typography noWrap title={props.project.name}>
          {props.project.name}
        </Typography>
        <Box typography="caption">Created by: {props.project.owner?.email || 'internal'}</Box>
      </Box>
    </Box>
  )
}
