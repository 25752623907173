import React, { useMemo } from 'react'
import {
  Box,
  Checkbox,
  DialogContent,
  FormControlLabel,
  FormControlLabelProps,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import _ from 'lodash'

import type { FormValues } from '../main-types'
import { exportMathDelimiterOptions, exportMathOptions } from '../main-types'
import { AdminSettings } from './admin-settings-state'

const defaultValues: FormValues = {
  hasOptionRationale: false,
  features: {
    export_file: [],
    export_math: '',
    export_math_delimiter: '',
    learn_v2: false,
    possible_keys: false,
    rationales: false,
  },
}

export const AdminManageEditors = ({ adminSettings }: { adminSettings: AdminSettings }) => {
  const { handleUpdateCustomer } = adminSettings
  const customer = adminSettings.customer!

  const { features, hasOptionRationale } = useMemo<FormValues>(() => {
    const result = _.pick(_.merge({}, defaultValues, _.cloneDeep(customer!)), [
      'features',
      'hasOptionRationale',
    ])

    return result
  }, [customer])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const result: FormValues['features'] = _.cloneDeep(features)

    const feat = /\./.test(e.target.name) ? e.target.name.split('.')[0] : e.target.name
    const val = /\./.test(e.target.name) ? e.target.name.split('.')[1] : e.target.checked

    result[feat] = val

    handleUpdateCustomer({ features: result, hasOptionRationale })
  }

  const getProps: (label: string, field: string) => FormControlLabelProps = (label, field) => {
    const feat = field.split('.')[0]
    const key = field.split('.')[1]

    const checked = key ? _.get(features, feat, '') === key : _.get(features, feat, false)

    return {
      disabled: false,
      onChange: handleChange,
      slotProps: { typography: { fontSize: 14 } },
      sx: { display: 'flex' },
      control: <Checkbox size="small" color="secondary" />,
      name: field,
      checked,
      label,
    }
  }

  return (
    <Box display="flex-col" gap={4}>
      <DialogContent sx={{ overflowY: 'auto' }}>
        <Box display="flex" gap={4} alignItems="center">
          <Box display="flex-col" justifyContent="" my={1}>
            <Typography variant="body2" gutterBottom className="">
              Export Format
            </Typography>
            <Box display="flex">
              {exportMathOptions.map((option) => (
                <FormControlLabel {...getProps(option, `export_math.${option}`)} key={option} />
              ))}
            </Box>
          </Box>

          {features.export_math === exportMathOptions[0] && (
            <Box display="flex-col" justifyContent="" my={1}>
              <Typography variant="body2" gutterBottom className="">
                LaTeX Delimiter characters
              </Typography>
              <Box display="flex">
                {exportMathDelimiterOptions.map((option) => (
                  <FormControlLabel
                    {...getProps(option, `export_math_delimiter.${option}`)}
                    key={option}
                  />
                ))}
              </Box>
            </Box>
          )}
        </Box>
      </DialogContent>
    </Box>
  )
}
