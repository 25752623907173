import { create } from 'zustand'

export type Project = {
  id: string
  name: string
  ownerId: string
  owner?: {
    id: string
    email: string
  }
  access: {
    userId: string
    write: boolean
    download: boolean
  }[]
}

type OnUpdate = (project?: Project) => void | Promise<unknown>

export type OnCloseSaveDialog = (
  arg: { project: Project; reason: 'confirm' } | { project: null; reason: 'cancel' },
) => void | Promise<unknown>

type State = {
  openKey: string
  items: Record<string, Project>

  edit: {
    open: boolean
    mode: 'create' | 'edit'
    project: Project | null
    onUpdate?: OnUpdate
  }

  save: {
    open: boolean
    mode: 'save' | 'move'
    selectedProjectId: string | null
    currentProjectId: string | null
    onClose?: OnCloseSaveDialog
  }

  share: {
    open: boolean
    projectId: string | null
    onUpdate?: OnUpdate
  }
}

type Actions = {
  openEditProject: (projectId: string, onUpdate?: OnUpdate) => void
  openCreateProject: (onUpdate: OnUpdate) => void
  onCloseProjectEdit: () => void

  openSaveItem: (onClose: OnCloseSaveDialog, initialValue?: string) => void
  openMoveItem: (onClose: OnCloseSaveDialog, currentProject: string) => void
  onCloseSaveItem: () => void

  openShareProject: (projectId: string, onUpdate: OnUpdate) => void
  onCloseShareProject: () => void

  reset: () => void
}

const initialState: State = {
  openKey: '',
  items: {},

  edit: {
    open: false,
    mode: 'create',
    project: null,
  },

  save: {
    open: false,
    mode: 'save',
    selectedProjectId: null,
    currentProjectId: null,
  },

  share: {
    open: false,
    projectId: null,
  },
}

export const useProjectsState = create<State & Actions>((set) => {
  return {
    ...initialState,

    reset() {
      set({ ...initialState })
    },

    openEditProject(projectId, onUpdate) {
      set(({ items }) => {
        if (!items[projectId]) {
          // eslint-disable-next-line no-console
          console.warn('Project not found', projectId)
        }

        return {
          edit: {
            open: true,
            mode: 'edit',
            project: items[projectId],
            onUpdate,
          },
        }
      })
    },

    openCreateProject(onUpdate) {
      set({
        edit: {
          open: true,
          mode: 'create',
          project: null,
          onUpdate,
        },
      })
    },

    onCloseProjectEdit() {
      set(({ edit }) => ({
        edit: {
          // keep these values to avoid flicking the UI on the close animation
          project: edit.project,
          mode: edit.mode,
          open: false,
        },
      }))
    },

    openSaveItem(onClose, initialValue) {
      set(({ save }) => ({
        save: {
          ...initialState.save,
          open: true,
          onClose,
          mode: 'save',
          selectedProjectId: initialValue || save.selectedProjectId,
        },
      }))
    },

    openMoveItem(onClose, currentProjectId) {
      set(({ save }) => ({
        save: {
          ...initialState.save,
          selectedProjectId:
            save.selectedProjectId === currentProjectId ? null : save.selectedProjectId,
          open: true,
          onClose,
          mode: 'move',
          currentProjectId,
        },
      }))
    },

    onCloseSaveItem() {
      set(({ save }) => ({
        save: {
          ...initialState.save,
          // should remember the selected project id
          selectedProjectId: save.selectedProjectId,
          mode: save.mode,
          open: false,
        },
      }))
    },

    openShareProject(projectId, onUpdate) {
      set({
        share: {
          open: true,
          projectId,
          onUpdate,
        },
      })
    },

    onCloseShareProject() {
      set(({ share }) => ({
        share: {
          ...share,
          open: false,
        },
      }))
    },
  }
})
