import { generatePath } from 'react-router-dom'

import { ITEM_SET, ITEM_SET2 } from '@core/constants/content-type'

export const LOGIN_ROUTE = '/'
export const AUTHOR_ROUTE = '/author'
export const AUTHOR_ITEM_ROUTE = '/author/item/:itemId'
export const AUTHOR_CLOZE_ROUTE = '/author/cloze/:itemId'
export const AUTHOR_ITEM_SET = '/author/item-set/:itemId'
export const DELIVER_ROUTE = '/deliver'
export const DELIVER_PROJECT_ROUTE = '/deliver/project/:projectId'
export const DELIVER_DRAFT = '/deliver/project/draft-items'
export const USERS_ROUTE = '/users'
export const NOT_FOUND = '/404'

export const routeMap = {
  LOGIN_ROUTE,
  LOGIN_SSO_ROUTE: '/login/sso',
  AUTHOR_ROUTE,
  AUTHOR_ITEM_ROUTE,
  AUTHOR_CLOZE_ROUTE,
  AUTHOR_ITEM_SET,
  DELIVER_ROUTE,
  DELIVER_PROJECT_ROUTE,
  DELIVER_DRAFT,
  NOT_FOUND,
  USERS_ROUTE,
} as const

export function getItemAuthorRoute(item: { contentType: string; id: string }) {
  const route = {
    [ITEM_SET]: AUTHOR_CLOZE_ROUTE,
    [ITEM_SET2]: AUTHOR_ITEM_SET,
  }

  return generatePath(route[item.contentType] || AUTHOR_ITEM_ROUTE, { itemId: item.id })
}

export type Route = (typeof routeMap)[keyof typeof routeMap]
