import DOMPurify from 'dompurify'
import parse from 'html-react-parser'
import _ from 'lodash'

import checkFunctions from './check'

export const countWords = (text: string) => {
  return text
    .trim()
    .split(/\s+/)
    .filter((word) => word !== '').length
}

export const truncate = (text: string, maxWords: number) => {
  return text.split(' ').splice(0, maxWords).join(' ')
}

/**
 * Check if string is empty by removing spaces and backslash
 */

export const isEmpty = (str = '') => !str.replace(/\s+/g, ' ').trim()

/**
 * Sanitize and parse html
 * @param {*} html
 * @param {*} opts
 * @returns
 */
export const html = (_html, opts = {}) => {
  return parse(DOMPurify.sanitize(_html), {
    ...opts,
  })
}

export const minWords = (text: string, min: number) => {
  if (countWords(text) < min) {
    return false
  }
  return true
}

export const maxWords = (text: string, max: number) => {
  if (countWords(text) > max) {
    return false
  }
  return true
}

export const validateInputCheck = (checks: any[] | undefined, value: string | string[]) => {
  if (!checks) return null
  for (const check of checks) {
    const checkFunction = checkFunctions[check.name]
    if (checkFunction) {
      const validated = checkFunction(value, check.params)
      if (!validated) return check
    }
  }
  return null
}

export const hasLessThanChars = (values: string[], numberChars: number) => {
  return values.some((value) => value.replaceAll(' ', '').length < numberChars)
}

export const getExtractedContent = (version: object, path: string) => {
  const content = _.get(version, path)
  return typeof content === 'string' ? content : ''
}

export const strToB64 = (value: string): string => {
  if (!value) return value
  const encoded = new TextEncoder().encode(value)
  const charCodes = Array.from(encoded)
  const base64String = String.fromCharCode(...charCodes)
  return btoa(base64String)
}

export const b64ToStr = (value: string): string => {
  if (!value) return value

  const strValue = atob(value)
  return new TextDecoder('utf-8').decode(
    Uint8Array.from({ length: strValue.length }, (element, index) => strValue.charCodeAt(index)),
  )
}
