import { useEffect, useRef } from 'react'

import { useApolloClient } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import Raven from '@core/api/Raven'
import { USER_LOGIN_TIME } from '@core/constants'
import TIMEOUTS from '@core/constants/timeouts'
import { useMainState } from '@core/main-state'

import * as queries from './main-queries'

/**
 * Determine whether the user is idle or not.
 * @returns - User idle state
 */
const userIsIdle = (): boolean => {
  return !window.document.hasFocus()
}

/**
 * Creates a loop that runs a series of actions repeatedly at a regular delay, managing
 * all of the error reporting and boilerplate up front.
 *
 * @param runCallback - a function to be looped
 * @param cleanupCallback - a function to cleanup the loop when stopped
 * @param delay - The delay by which the loop repeats in milliseconds
 */
const useInterval = (runCallback: () => void, cleanupCallback: () => void, delay: number) => {
  const runCallbackRef = useRef(runCallback)
  const cleanupCallbackRef = useRef(cleanupCallback)

  // Remember the latest callbacks
  useEffect(() => {
    runCallbackRef.current = runCallback
  }, [runCallback])
  useEffect(() => {
    cleanupCallbackRef.current = cleanupCallback
  }, [cleanupCallback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      try {
        runCallbackRef.current()
      } catch (e) {
        Raven.captureException(e.reason)
      }
    }
    if (delay) {
      const id = setInterval(tick, delay)
      return () => {
        clearInterval(id)
        cleanupCallbackRef.current()
      }
    }
  }, [delay])
}

const getFirstActiveTime = (): number => {
  const v = Number(window.localStorage.getItem(USER_LOGIN_TIME))
  return Number.isNaN(v) ? 0 : v
}

const DISPLAY_WARNING_AT_TIME =
  TIMEOUTS.MAX_AUTH_SESSION_LENGTH - TIMEOUTS.UI_AUTH_SESSION_EXPIRATION_WARNING

let lastActiveTime = Date.now()

/**
 * A React component that runs periodic background events in a loop to refresh the user's token and
 * update their last active time.
 */
export const PeriodicBackgroundTasks = ({ userId }: { userId: string }) => {
  const { isAuthenticated, isLoading, logout } = useAuth0()

  const client = useApolloClient()

  useInterval(
    async () => {
      if (isLoading || !isAuthenticated) return

      const now = Date.now()
      const isIdle = userIsIdle()

      if (isIdle) {
        return
      }

      const firstActiveTime = getFirstActiveTime()

      if (!isIdle) {
        lastActiveTime = now
      }
      const isIdleTooLong = now - lastActiveTime > TIMEOUTS.MAX_USER_IDLE_TIME

      if (isIdleTooLong) {
        console.debug('Logout: isIdleTooLong')
        await logout({
          logoutParams: {
            returnTo: `${window.document.location.origin}?auth0ErrorParam=expired`,
          },
        })
      } else if (!isIdle) {
        // the session is still valid, and the user is not idle, so update their last access
        // time in the database
        const variables = { id: userId, lastAccess: new Date().toISOString() }
        await client.mutate({
          mutation: queries.UPDATE_USER_LAST_ACCESS,
          variables,
        })
      }

      // Check to see if we should show logout warning
      const showWarning = now - firstActiveTime > DISPLAY_WARNING_AT_TIME

      if (useMainState.getState().userSessionWarningVisible !== showWarning) {
        useMainState.setState({ userSessionWarningVisible: showWarning })
        // Schedule a logout if the banner is being displayed for the first time.
        if (showWarning) {
          setTimeout(async () => {
            // eslint-disable-next-line no-console
            console.debug('Logout: Forced user to logout after banner warning expired')
            await logout({
              logoutParams: {
                returnTo: `${window.document.location.origin}?auth0ErrorParam=expired`,
              },
            })
          }, TIMEOUTS.UI_AUTH_SESSION_EXPIRATION_WARNING)
        }
      }
    },
    // Cleanup function for operating the abortController
    () => {},
    TIMEOUTS.TOKEN_REFRESH,
  )

  return null
}
