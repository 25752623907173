import React from 'react'
import { Link, StyleProps } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

import GenerateLogo from '@assets/images/logo-ft-generate-dark.svg?react'

const styles = {
  logoContainer: {
    textDecoration: 'none',
    height: 56,
    display: 'flex',
    alignItems: 'center',
    marginTop: -0.5,
    '& svg': {
      height: 48,
    },
  },
} satisfies StyleProps

const Logo = (props: { onClear: () => void }) => {
  return (
    <Link component={RouterLink} to="/author" onClick={props.onClear} sx={styles.logoContainer}>
      <GenerateLogo data-testid="finetune-logo" />
    </Link>
  )
}

export default Logo
