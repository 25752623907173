import _ from 'lodash'

import { getClient } from '@core/api/api-state'
import { getUserRole } from '@core/main-state'

import * as queries from './content-version-queries'
import type { Instance, Items } from './content-version-types'

const contentVersionMapping = {
  content: 'content',
  qualityMetrics: 'quality_metrics',
  comments: 'comments',
  submodels: 'submodels',
  externalMetadata: 'external_metadata',
  inputs: 'inputs',
  itemBias: 'item_bias',
  itemKeyCheck: 'item_key_check',
}

const ignoreFields = ['__typename', 'type', 'id']

export const updateItems = (rootId: string, items: Items, itemChanges: Instance['itemChanges']) => {
  const client = getClient()
  const { role } = getUserRole()

  const variables: queries.UpdateContentVersionsInput = {
    rootId,
    items: [],
  }

  for (const [itemKey, fields] of Object.entries(itemChanges)) {
    const item = items[itemKey]

    const update = { item_id: item.id }
    const changes = _.pick(item.currentContent, Object.keys({ ...fields, qualityMetrics: true }))

    for (const [key, rawValue] of Object.entries(changes)) {
      if (ignoreFields.includes(key)) continue

      let value = rawValue

      if (key === 'comments') {
        value = (value as any[]).map((comment) => ({
          name: comment.name,
          value: comment.value,
        }))
      }

      const path = contentVersionMapping[key]

      if (path) {
        update[path] = value
      } else {
        console.warn('updateItems: unknown key', { key, value })
      }
    }

    variables.items.push(update)
  }

  return client.mutate({
    mutation: queries.UPDATE_CONTENT_VERSIONS,
    variables,
    context: { role },
  })
}
