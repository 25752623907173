/* eslint-disable no-param-reassign */

import { immer } from 'zustand/middleware/immer'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

import * as queries from './export-queries'

export type ExportJob = queries.ExportJob & { label?: string }

export type State = {
  // the floating right bottom menu/dialog
  exportStatus: {
    jobs: ExportJob[] | null
  }

  exportDialog: {
    open: boolean
    selectedJobId: string | null
    tab: number
    selectedJobIds: Set<string>
  }
}

export const createPlaceholderJob = (jobId: string, exportType: string): ExportJob => ({
  id: jobId,
  status: 'pending',
  createdAt: new Date().toISOString(),
  fileName: '',
  label: exportType,
  exportType,
  totalCompleted: 0,
  totalFailed: 0,
  totalItems: 0,
  errorDetails: {} as queries.ErrorDetails,
})

export const initialState: State = {
  exportStatus: {
    jobs: null,
  },

  exportDialog: {
    open: false,
    selectedJobId: null,
    tab: 0,
    selectedJobIds: new Set(),
  },
}

type Actions = {
  reset: () => void
  updateDialog: (
    partialOrCallback: Partial<State['exportDialog']> | ((prev: State['exportDialog']) => void),
  ) => void
  add: (exportJob: ExportJob) => void
  remove: (exportJobId: string) => void
}

export const useExportJobs = createWithEqualityFn(
  immer<State & Actions>((set) => {
    const addExportJob = (exportJob: ExportJob) => {
      set((prev) => {
        const exportJobs = prev.exportStatus.jobs || []
        const index = exportJobs.findIndex((x) => x.id === exportJob.id)

        if (index === -1) {
          exportJobs.push(exportJob)
        } else {
          exportJobs[index] = { ...exportJobs[index], ...exportJob }
        }

        prev.exportStatus.jobs = exportJobs
      })
    }

    return {
      ...initialState,

      reset() {
        set(initialState)
      },

      updateDialog(partialOrCallback) {
        set((prev) => {
          if (typeof partialOrCallback === 'function') {
            partialOrCallback.apply(null, [prev.exportDialog])
          } else {
            prev.exportDialog = { ...prev.exportDialog, ...partialOrCallback }
          }
        })
      },

      add(exportJob) {
        return addExportJob(exportJob)
      },

      remove(exportJobId) {
        set((prev) => {
          prev.exportStatus.jobs = (prev.exportStatus.jobs || []).filter(
            (x) => x.id !== exportJobId,
          )
        })
      },

      set,
    }
  }),
  shallow,
)
