import React from 'react'
import LoadingButton from '@mui/lab/LoadingButton'
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

import { formatTimeAgo } from '@core/utils/date'

import { Customer } from '../main-types'
import { AdminSettings } from './admin-settings-state'

export const AdminSwitchCustomer = ({ adminSettings }: { adminSettings: AdminSettings }) => {
  const { state, setState, customer, handleSwitchCustomer, handleClose } = adminSettings
  const enableSwitch = state.selectedCustomerId && state.selectedCustomerId !== customer!.id

  React.useEffect(() => {
    if (!state.customers) {
      adminSettings.loadCustomers()
    }
  }, [state.customers])

  return (
    <Box component="form" onSubmit={handleSwitchCustomer}>
      <DialogContent>
        <Autocomplete
          loading={!state.customers}
          options={state.customers || []}
          sx={{ pb: 3 }}
          onChange={(e, value) => setState({ selectedCustomerId: value?.id })}
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              label={state.customers ? 'Select a customer' : 'Loading...'}
              fullWidth
              helperText="All tabs will reload after this change"
              disabled={!state.customers}
            />
          )}
          value={state.customers?.find((item) => item.id === state.selectedCustomerId) || null}
          getOptionLabel={(option) => option.name}
          renderOption={(props, option: Customer) => (
            <ListItem {...props} key={option.id} sx={{ padding: '0px !important' }}>
              <ListItemButton role={undefined} dense>
                <ListItemText
                  id={option.id}
                  color="text.primary"
                  secondaryTypographyProps={{ color: 'primary.main' }}
                  primary={
                    <>
                      {option.id === customer!.id ? '* Current - ' : ''}
                      {option.name}
                    </>
                  }
                  secondary={
                    <>
                      <b>Created at:</b> {formatTimeAgo(option.createdAt)[0]}
                    </>
                  }
                />
              </ListItemButton>
            </ListItem>
          )}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <LoadingButton
          variant="contained"
          disabled={!enableSwitch}
          type="submit"
          loading={state.loading}
        >
          Switch Customer
        </LoadingButton>
      </DialogActions>
    </Box>
  )
}
