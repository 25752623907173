import { immer } from 'zustand/middleware/immer'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

export type Section = {
  title: string
  description: string
  resource_ids: string[]
}

export type Resource = {
  title: string
  description: string
  sections: { default: Section } | Record<string, Section>
}

export type ResourceSettings = {
  citation_field: string
  citation_value_field: string
  item_starter: boolean
  item_rationale: boolean
  resource_inputs?: string[]
  resources: Record<string, Resource>
}

export type Match = {
  id: string
  citation: string
  book_name: string
  description: string
  root_id: string
  section_name: string
  siblings: {
    id: string
    order: number
    description: string
  }[]
}

export type ResourceResult = {
  id: string
  title: string
  results: Match[]
}

export type SearchParams = {
  query: string
  aiModelId: string
  resourceIds?: string[]
}

export type OnSelectMatch = (
  match: Match & { selection?: string },
  resourceSettings: ResourceSettings,
) => void

export type State = {
  selection: {
    match: Match
    submodels: string[]
  } | null

  resultDialog: {
    open: boolean
    loading: boolean
    error: string

    resourceResults?: Record<string, ResourceResult>

    params?: SearchParams

    tab: number
    selectedMatch: number
    selection: string
  }
}

type Actions = {
  reset: () => void
}

const initialState: State = {
  selection: null,
  resultDialog: {
    open: false,
    loading: false,
    error: '',
    tab: 0,
    selectedMatch: 0,
    selection: '',
  },
}

export const useResources = createWithEqualityFn(
  immer<State & Actions>((set) => {
    return {
      ...initialState,

      reset() {
        set(initialState)
      },
    }
  }),
  shallow,
)
