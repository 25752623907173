import React from 'react'
import LoadingButton from '@mui/lab/LoadingButton'
import { Box, Button, DialogActions, DialogContent } from '@mui/material'
import { useForm } from 'react-hook-form'

import { TextField, TextFieldProps } from '@core/components/textfield/textfield'

import { AdminSettings } from './admin-settings-state'

type Values = {
  name: string
  identifier: string
}

const defaultValues: Values = {
  name: '',
  identifier: '',
}

export const AdminAddCustomer = ({ adminSettings }: { adminSettings: AdminSettings }) => {
  const { handleClose, handleAddCustomer, loadCustomers } = adminSettings

  const form = useForm({ defaultValues })
  const { register, formState, handleSubmit } = form

  const getProps = (field: keyof Values): TextFieldProps => {
    const error = formState.errors[field]
    let message = error?.message

    if (error?.type === 'maxLength') {
      message = 'Max length of 128'
    } else if (error?.type === 'minLength') {
      message = 'Min length of 3'
    }

    return {
      error: Boolean(error),
      helperText: message,
    }
  }

  const onSubmit = async (values: Values) => {
    const result = await handleAddCustomer(values)

    if (result.success) {
      loadCustomers()
      handleClose()
    } else {
      form.setError('identifier', { message: result.reason })
    }
  }

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <DialogContent>
        <TextField
          autoFocus
          required
          {...register('name', {
            maxLength: 128,
            minLength: 3,
            required: 'Required',
          })}
          {...getProps('name')}
          label="Name"
          aria-label="Customer Name"
          fullWidth
        />
        <Box mt={2}>
          <TextField
            required
            {...register('identifier', {
              maxLength: 128,
              minLength: 3,
              required: 'Required',
            })}
            {...getProps('identifier')}
            label="Identifier"
            aria-label="Customer Identifier"
            fullWidth
          />
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <LoadingButton
          variant="contained"
          disabled={formState.isSubmitting || !formState.isDirty}
          loading={formState.isSubmitting}
          type="submit"
        >
          Add Customer
        </LoadingButton>
      </DialogActions>
    </Box>
  )
}
