import React, { forwardRef } from 'react'
import {
  FormControl,
  TextField as MuiTextField,
  StandardTextFieldProps,
  StyleProps,
} from '@mui/material'

declare module '@mui/material/TextField' {
  interface TextFieldPropsSizeOverrides {
    large: true
  }
}

export const styles = {
  root: {
    m: 0,
    '& fieldset': { border: 'none' },
    '& .MuiInputBase-root': {
      bgcolor: 'background.default',
      border: '2px solid #dfe1e5',
      padding: '10px 16px',
      borderRadius: '3px',
      '&.Mui-focused': {
        borderColor: 'text.focus',
      },
      '&.Mui-error': {
        borderColor: 'error.main',
      },
      '&.MuiInputBase-colorSuccess': {
        borderColor: 'success.main',
      },
      '&.MuiInputBase-colorWarning': {
        borderColor: 'warning.main',
      },
      '&.Mui-disabled': {
        borderColor: 'background.blueGrey',
        backgroundColor: 'background.blueGrey',
      },
    },
    '& .MuiInputBase-input': {
      padding: 0,
      color: 'text.indigo',
      '::placeholder': {
        color: 'text.lightSlateGray',
      },
      '&.Mui-disabled': {
        color: 'text.cadetBlue',
      },
    },
    '& .MuiInputBase-sizeLarge': {
      py: 1.5,
      px: 2,
    },
  },
  label: {
    position: 'relative',
    fontSize: 14,
    fontWeight: 500,
    transform: 'unset',
    marginBottom: '8px',
    color: 'text.primary',
    '&.Mui-error, &.Mui-disabled, &.Mui-focused': {
      color: 'text.primary',
    },
  },
  helperText: {
    mt: 0.5,
    ml: 0,
    color: 'text.secondary',
    fontSize: 13,
    fontWeight: 500,
    lineHeight: '16px',
    '&.Mui-disabled': {
      color: 'text.greyBlue',
    },
    '&[color=success]': {
      color: 'other.checkedGreen',
    },
  },
} satisfies StyleProps

export interface TextFieldProps extends StandardTextFieldProps {
  ref?: React.Ref<HTMLInputElement>
}

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (props: TextFieldProps, ref: React.Ref<HTMLInputElement>) => {
    const { color, error, label, required, helperText, 'aria-label': ariaLabel, ...rest } = props

    return (
      <FormControl fullWidth>
        <MuiTextField
          required={required}
          label={label}
          helperText={helperText}
          InputProps={{ 'aria-label': ariaLabel }}
          InputLabelProps={{ shrink: true, sx: styles.label }}
          FormHelperTextProps={{
            sx: styles.helperText,
            color,
          }}
          sx={styles.root}
          color={color}
          error={error}
          inputRef={ref}
          {...rest}
        />
      </FormControl>
    )
  },
)

TextField.displayName = 'TextField'
