import React from 'react'
import { Box, BoxProps, StyleProps } from '@mui/material'

const styles = {
  root: {
    width: '100%',
    overflowY: 'auto',
    scrollbarGutter: 'stable',
  },
} satisfies StyleProps

type ScrollBarContainerProps = {
  children: React.ReactNode
  style?: React.CSSProperties
} & BoxProps<'div'>

export const ScrollBarContainer = (props: ScrollBarContainerProps) => {
  return <Box role="presentation" sx={styles.root} {...props} />
}

export default ScrollBarContainer
