import { type ErrorDetails } from './export-queries'

type ErrorResult = {
  title: string
  message: string
  code: string
}

// api/src/chalicelib/exceptions.py
export const getErrorMessage = (errorDetails?: ErrorDetails): ErrorResult => {
  const result: ErrorResult = {
    title: 'Something went wrong',
    message: 'An error occurred while exporting the items',
    code: 'unknown',
  }

  if (!errorDetails?.code) return result

  result.code = errorDetails.code

  if (
    ['InvalidIntegrationCredentialsException', 'ProBuilderInvalidConfigException'].includes(
      errorDetails.code,
    )
  ) {
    result.message = errorDetails.message!
  }

  if (errorDetails.code === 'ProBuilderCreateItemException') {
    result.message = 'An error occurred while creating the item'
  }

  if (errorDetails.code === 'ProBuilderMappingException') {
    let prefix = ''

    if (errorDetails.skipped) {
      prefix = 'Skipped. '
    }

    result.message = `${prefix}Failed to map the item: ${errorDetails.reason}`
  }

  return result
}
