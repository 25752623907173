import React from 'react'
import { Link, type StyleProps, type SxProps } from '@mui/material'
import { merge } from 'lodash'
import { Link as RouterLink } from 'react-router-dom'

import GenerateLogo from '@assets/images/logo-ft-generate-dark.svg?react'
import { AUTHOR_ROUTE } from '@core/constants/routes'

const styles = {
  logo: {
    textDecoration: 'none',
    '& svg': {
      height: 48,
      display: 'block',
    },
  },
} satisfies StyleProps

type LogoProps = {
  sx?: SxProps
}

/**
 * Renders finetune generate logo
 * */
const Logo = ({ sx = {} }: LogoProps) => {
  return (
    <Link component={RouterLink} to={AUTHOR_ROUTE} sx={merge(styles.logo, sx)}>
      <GenerateLogo data-testid="finetune-logo" />
    </Link>
  )
}

export default Logo
