export const dateFormatter = (options, date) => new Intl.DateTimeFormat('en', options).format(date)

export const getDateHour = (date, options) =>
  dateFormatter({ hour: '2-digit', minute: '2-digit', timeZoneName: 'short', ...options }, date)

export const getDateYear = (date) =>
  dateFormatter(
    {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    },
    date
  )

const formatter = new Intl.RelativeTimeFormat(undefined, {
  numeric: 'auto',
})

const DIVISIONS: { amount: number; name: Intl.RelativeTimeFormatUnit }[] = [
  { amount: 60, name: 'seconds' },
  { amount: 60, name: 'minutes' },
  { amount: 24, name: 'hours' },
  { amount: 7, name: 'days' },
  { amount: 4.34524, name: 'weeks' },
  { amount: 12, name: 'months' },
  { amount: Number.POSITIVE_INFINITY, name: 'years' },
]

export function formatTimeAgo(date: string): [string, number] {
  // @ts-expect-error
  const secondsAgo = (new Date(date) - new Date()) / 1000
  let duration = secondsAgo

  for (let i = 0; i <= DIVISIONS.length; i += 1) {
    const division = DIVISIONS[i]
    if (Math.abs(duration) < division.amount) {
      return [
        formatter.format(Math.round(duration), division.name),
        Math.abs(Math.round(secondsAgo)),
      ]
    }
    duration /= division.amount
  }

  return ['never', 0]
}
