// this file is used to easily mock window.location in tests
export default {
  reload() {
    window.location.reload()
  },
  navigateTo(path: string) {
    window.location.href = path
  },
  getCurrentPath() {
    return window.location.pathname
  },
}
