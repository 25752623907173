import React from 'react'
import { Box } from '@mui/material'
import Slider from '@mui/material/Slider'
import Typography from '@mui/material/Typography'

import FTGTooltip from '@core/components/FTGTooltip'

type FTGSliderProps = {
  title?: string
  disabled?: boolean
  notSupported?: boolean
  value?: number
  onChange: (event: Event, value: number | number[], activeThumb: number) => void
}

const lowValue = 0
const mediumValue = 50
const highValue = 100
const marks = [
  {
    value: lowValue,
    label: 'Low',
  },
  {
    value: mediumValue,
    label: 'Medium',
  },
  {
    value: highValue,
    label: 'High',
  },
]

const FTGSlider = React.forwardRef<HTMLInputElement, FTGSliderProps>(
  ({ title, disabled, onChange, value = mediumValue, notSupported }, ref) => {
    const inputLabel = `${title} slider`

    return (
      <Box aria-label="ftg-slider">
        {title && (
          <Typography
            variant="h5"
            gutterBottom
            id={inputLabel}
            sx={{ opacity: disabled || notSupported ? 0.5 : 1 }}
          >
            {title}
          </Typography>
        )}
        <FTGTooltip
          data-testid="ftg-slider-tooltip"
          title={
            notSupported ? 'The model you have selected does not support creativity levels' : ''
          }
          placement="left"
          leaveDelay={200}
        >
          <div>
            <Slider
              value={value}
              aria-labelledby={inputLabel}
              step={null}
              valueLabelDisplay="off"
              marks={marks}
              color="secondary"
              disabled={disabled || notSupported}
              min={lowValue}
              max={highValue}
              onChange={onChange}
              slotProps={{
                input: {
                  ref,
                  // @ts-ignore
                  'data-testid': 'ftg-slider-input',
                },
              }}
            />
          </div>
        </FTGTooltip>
      </Box>
    )
  }
)

FTGSlider.displayName = 'FTGSlider'

export default FTGSlider
