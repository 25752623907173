import React, { useEffect, useState } from 'react'
import LogoDevIcon from '@mui/icons-material/LogoDev'
import { MenuItem, MenuItemProps } from '@mui/material'
import { useParams } from 'react-router-dom'

import { useLazyQuery } from '@apollo/client'
import { useAppSelector } from '@core/store'
import { DEBUG_ITEM } from '@pages/author/author-queries'

type DebugItemProps = Omit<MenuItemProps, 'onClick' | 'disabled'>

export const DebugItem = (props: DebugItemProps) => {
  const params = useParams()
  const [disabled, setDisabled] = useState(true)
  const batchFailed = useAppSelector((state) => state.author.batchFailed)
  const itemsIds = useAppSelector((state) => state.author.paginatedItemsIds)
  const itemId = params.itemId! || itemsIds[0]

  const [debugItem, { loading }] = useLazyQuery(DEBUG_ITEM, {
    variables: { itemId },
    context: { role: 'internal_admin' },
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      if (!data?.item) {
        console.warn('No item found when debugging item', itemId)
        return
      }

      console.group('Debugging Item %s', itemId)
      console.info(data.item)
      console.groupEnd()
    },
  })

  useEffect(() => {
    if ((batchFailed && itemsIds.length > 0) || (params.itemId && !loading)) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [batchFailed, itemsIds])

  return (
    <MenuItem {...props} onClick={() => debugItem()} disabled={disabled}>
      <LogoDevIcon sx={{ mr: 1, color: 'text.grayishBlue' }} />
      Debug Item{loading && '...'}
    </MenuItem>
  )
}
