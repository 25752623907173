import React from 'react'
import { Dialog, DialogTitle, Grid, Tab, Tabs } from '@mui/material'

import { AdminAddCustomer } from './admin-add-customer'
import { AdminManageBFF } from './admin-manage-bff'
import { AdminManageCustomer } from './admin-manage-customer'
import { AdminManageEditors } from './admin-manage-editors'
import { AdminManageIntegrations } from './admin-manage-integrations'
import { AdminManageModels } from './admin-manage-models'
import type { AdminSettings } from './admin-settings-state'
import { AdminSwitchCustomer } from './admin-switch-customer'
import { adminViewOptions } from './admin-views'

export default function AdminSettingsDialog({ adminSettings }: { adminSettings: AdminSettings }) {
  const { state, handleClose, user, customer } = adminSettings
  const viewIndex = adminViewOptions.findIndex((v) => v.key === state.view)
  const view = adminViewOptions[viewIndex]

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    adminSettings.setState({ view: adminViewOptions[newValue].key })
  }

  if (!user?.internalAdmin || !customer || !view) return null

  return (
    <Dialog open={state.open} onClose={handleClose} fullWidth scroll="body" maxWidth="lg">
      <Grid container spacing={2} sx={{ minHeight: '70vh', pl: 2, pt: 2 }}>
        <Grid item xs={12} sm={3} sx={{ borderRight: '1px solid #f2f2f2' }}>
          <Tabs
            value={viewIndex}
            onChange={handleChangeTab}
            aria-label="admin settings tabs"
            orientation="vertical"
          >
            {adminViewOptions.map((option, index) => (
              <Tab
                key={option.key}
                value={index}
                icon={<option.icon fontSize="small" />}
                label={option.label}
                iconPosition="start"
                sx={{ p: 1, justifyContent: 'flex-start', minHeight: 40 }}
              />
            ))}
          </Tabs>
        </Grid>
        <Grid item xs={12} sm={9} sx={{ p: 2 }}>
          <DialogTitle
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: 1,
              mb: 2,
            }}
          >
            Admin Settings - {view.label}
          </DialogTitle>
          {state.view === 'ADD_CUSTOMER' && <AdminAddCustomer adminSettings={adminSettings} />}
          {state.view === 'SWITCH_CUSTOMER' && (
            <AdminSwitchCustomer adminSettings={adminSettings} />
          )}
          {state.view === 'MANAGE_CUSTOMER' && (
            <AdminManageCustomer adminSettings={adminSettings} />
          )}
          {state.view === 'MANAGE_MODELS' && <AdminManageModels />}
          {state.view === 'MANAGE_INTEGRATIONS' && <AdminManageIntegrations />}
          {state.view === 'MANAGE_EDITORS' && <AdminManageEditors adminSettings={adminSettings} />}
          {state.view === 'MANAGE_BFF' && <AdminManageBFF adminSettings={adminSettings} />}
        </Grid>
      </Grid>
    </Dialog>
  )
}
