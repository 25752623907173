import React, { memo, useMemo, useState } from 'react'
import FolderOpenIcon from '@mui/icons-material/FolderOpen'
import SearchIcon from '@mui/icons-material/Search'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Fade,
  Input,
  InputAdornment,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material'

import { useQuery } from '@apollo/client'
import FTGCheckbox from '@core/components/FTGCheckbox'
import FTGTooltip from '@core/components/FTGTooltip'
import { getUserRole } from '@core/main-state'

import * as service from './projects-service'
import { useProjectsState, type Project } from './projects-state'

const options = [
  ['read', 'User can view items'],
  ['write', 'User can edit items'],
  ['download', 'User can download items'],
]

function UserRow({
  user,
  project,
  onUpdate,
}: {
  user: service.User
  project: Project
  onUpdate: () => Promise<void>
}) {
  const [loading, setLoading] = useState(false)
  const { customer } = getUserRole()

  const isOwner = user.id === project.owner?.id
  let access: service.AccessOptions = user.access[0]

  if (!access) {
    access = isOwner
      ? { read: true, write: true, download: true }
      : { read: false, write: false, download: false }
  }

  const handleChange = async (key: string, checked: boolean) => {
    setLoading(true)
    const newAccess = { ...access, [key]: checked }

    if (['write', 'download'].includes(key) && checked) {
      newAccess.read = true
    }

    if (key === 'read' && !checked) {
      newAccess.download = false
      newAccess.write = false
    }

    await service.updateProjectUser({
      ...newAccess,
      customerId: customer.id,
      userId: user.id,
      projectId: project.id,
    })
    await onUpdate()

    setLoading(false)
  }

  return (
    <ListItem
      divider
      data-testid={`share:${user.email}`}
      sx={{
        '& .MuiFormControlLabel-label': {
          fontWeight: 'normal',
          textTransform: 'capitalize',
        },
      }}
    >
      <ListItemText
        sx={{ px: 1, flexShrink: 0 }}
        primary={[user.firstName, user.lastName].join(' ').trim()}
        secondary={user.email}
      />

      {options.map(([key, tooltip]) => (
        <FTGTooltip title={tooltip} key={key}>
          <Box>
            <FTGCheckbox
              label={key}
              onChange={(e) => handleChange(key, e.target.checked)}
              checked={access[key]}
              disabled={loading}
            />
          </Box>
        </FTGTooltip>
      ))}
    </ListItem>
  )
}

type ProjectShareContentProps = {
  projectId: string
  onClose: () => void
}

// only renders when the dialog is open
const ProjectShareContent = (props: ProjectShareContentProps) => {
  const [searchValue, setSearch] = useState('')
  const currentProject = useProjectsState((state) => state.items[props.projectId])

  const { data, loading, refetch } = useQuery(service.GET_PROJECT_USERS, {
    variables: { projectId: props.projectId },
    context: { role: 'customer_admin' },
    skip: !props.projectId,
  })

  const onUpdate = React.useCallback(async () => {
    await refetch()
  }, [refetch])

  const users = useMemo(() => {
    return data?.users.filter((user) => {
      const lowerValue = searchValue.toLowerCase()
      return (
        user.email.toLowerCase().includes(lowerValue) ||
        `${user?.firstName} ${user?.lastName}`.toLowerCase().includes(lowerValue)
      )
    })
  }, [searchValue, data?.users])

  if (!currentProject) return null

  return (
    <>
      <DialogContent sx={{ overflowY: 'initial' }}>
        <Box typography="h3">Selected Folder</Box>
        <Box typography="h5" display="flex" alignItems="center" my={2}>
          <Box>
            <FolderOpenIcon sx={{ mr: 1 }} />
          </Box>
          <Box>
            {currentProject.name}
            <Box typography="caption">Created by: {currentProject.owner?.email || 'internal'}</Box>
          </Box>
        </Box>
        <Box mt={3} sx={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
          <Box typography="h5">Sharing & Permissions</Box>
          <Input
            size="small"
            value={searchValue}
            sx={{
              px: 1,
              bgcolor: '#ebecf0',
              borderRadius: 0.75,
              '& fieldset': { border: 'none' },
            }}
            inputProps={{ 'aria-label': 'Search users' }}
            placeholder="Search users"
            endAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
            onChange={(e) => setSearch(e.target.value)}
          />
        </Box>
      </DialogContent>
      {loading && (
        <Box height={500}>
          <Fade in={loading} unmountOnExit>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 500,
                width: '100%',
                mb: 3,
                position: 'absolute',
                left: 0,
                top: 0,
              }}
            >
              <CircularProgress />
            </Box>
          </Fade>
        </Box>
      )}
      {!loading && (
        <List sx={{ height: 500, overflowY: 'auto', mb: 3 }}>
          {users?.map((user) => (
            <UserRow key={user.id} user={user} project={currentProject} onUpdate={onUpdate} />
          ))}

          {!users?.length && !loading && searchValue && (
            <Box pl={3}>
              <Typography variant="h5" gutterBottom>
                No Users Found
              </Typography>
              <Button
                color="secondary"
                onClick={() => setSearch('')}
                sx={{
                  color: 'text.linkBlue',
                }}
              >
                Clear Filter
              </Button>
            </Box>
          )}
        </List>
      )}

      <DialogActions>
        <Button variant="contained" color="secondary" onClick={props.onClose}>
          Done
        </Button>
      </DialogActions>

      {loading && (
        <Box>
          <LinearProgress color="secondary" />
        </Box>
      )}
    </>
  )
}

const ProjectShareDialog = () => {
  const { open, projectId, onUpdate } = useProjectsState((state) => state.share)
  const onClose = useProjectsState((state) => state.onCloseShareProject)

  const handleClose = () => {
    if (onUpdate) {
      onUpdate()
    }

    onClose()
  }

  return (
    <Dialog open={open} onClose={handleClose} data-full-page-width>
      <ProjectShareContent projectId={projectId!} onClose={handleClose} />
    </Dialog>
  )
}

export default memo(ProjectShareDialog)
