import React, { memo } from 'react'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'

import FTGDropdown from '@core/components/FTGDropdown'
import { useAppSelector } from '@core/store'

import { useProjectsState, type Project } from './projects-state'

const confirmButtonLabel = 'Confirm save item'

type ProjectSaveItemContentProps = {
  isSubmitting: boolean
  onSubmit: (e: React.FormEvent) => Promise<void>
  onClose: () => void
}

const ProjectSaveItemContent = (props: ProjectSaveItemContentProps) => {
  const openCreateProject = useProjectsState((state) => state.openCreateProject)
  const { selectedProjectId, currentProjectId, mode } = useProjectsState((state) => state.save)

  const projects = useProjectsState((state) => state.items)
  const selectedProject = selectedProjectId ? projects[selectedProjectId] : null

  const user = useAppSelector((state) => state.main.user)
  const isAdmin = user?.role === 'Admin'

  const handleChange = (project: Project) => {
    useProjectsState.setState((prevState) => ({
      save: { ...prevState.save, selectedProjectId: project.id },
    }))
  }

  const options = [
    {
      name: 'Create a folder',
      action: () =>
        openCreateProject((project) => {
          if (!project) return

          handleChange(project)

          // focus the confirm button after creating a project
          setTimeout(() => {
            const confirmButton = document.querySelector(
              `button[aria-label="${confirmButtonLabel}"]`,
            ) as HTMLButtonElement
            confirmButton?.focus()
          }, 500)
        }),
      tooltip: 'test',
    },
    ...Object.values(projects).map((project) => {
      const [access] = isAdmin ? [{ write: true }] : project.access
      const isOwner = user?.id === project.owner?.id
      let canWrite = access ? access?.write : isOwner

      let name = <Box component="span">{project.name}</Box>

      if (mode === 'move' && project.id === currentProjectId) {
        name = (
          <Box>
            {name}
            <br />
            <Box typography="caption">You cannot move an item to the same folder</Box>
          </Box>
        )
        canWrite = false
      } else if (!canWrite) {
        name = (
          <Box>
            {name}
            <br />
            <Box typography="caption">You do not have write access to this folder</Box>
          </Box>
        )
      }

      return {
        ...project,
        projectName: project.name,
        disabled: !canWrite,
        name,
        tooltip: project.name,
        tooltipMaxWidth: 200,
      }
    }),
  ]

  let title = 'Your item will be saved to the folder'

  if (mode === 'move') {
    title = 'Your item will be moved to the folder'
  }

  return (
    <Box component="form" onSubmit={props.onSubmit}>
      <DialogTitle>
        <Box typography="h3" component="span">
          {title}
        </Box>
      </DialogTitle>
      <DialogContent sx={{ overflowY: 'initial' }}>
        <Box my={3}>
          <FTGDropdown
            options={options}
            setValue={handleChange}
            value={selectedProject?.id || undefined}
            buttonLabel={selectedProject?.name}
            placeholder="Select or create a folder"
            sx={{ bgcolor: 'background.button', my: 2 }}
            menuSx={{ minWidth: 300 }}
            optionsSx={{ maxWidth: 400 }}
            testid="save-item-menu"
          />
        </Box>

        <Box>Please ensure that you are saving this item to the desired folder.</Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={props.onClose} color="secondary" disabled={props.isSubmitting}>
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          color="secondary"
          type="submit"
          disabled={props.isSubmitting || !selectedProject}
          aria-label={confirmButtonLabel}
          loading={props.isSubmitting}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Box>
  )
}

export const ProjectSaveItemDialog = () => {
  const onCloseSaveItem = useProjectsState((state) => state.onCloseSaveItem)
  const { selectedProjectId, onClose } = useProjectsState((state) => state.save)

  const isOpen = useProjectsState((state) => !state.edit.open && state.save.open)

  const projects = useProjectsState((state) => state.items)
  const [isSubmitting, setIsSubmitting] = React.useState(false)

  const selectedProject = selectedProjectId ? projects[selectedProjectId] : null

  const handleClose = () => {
    onCloseSaveItem()

    if (onClose) {
      onClose({
        project: null,
        reason: 'cancel',
      })
    }
  }

  const onSubmit: ProjectSaveItemContentProps['onSubmit'] = async (e) => {
    e.preventDefault()

    if (!selectedProject || !onClose) return

    setIsSubmitting(true)

    try {
      await onClose({
        project: selectedProject,
        reason: 'confirm',
      })
      onCloseSaveItem()
    } catch {
      // nothing to do here
    }

    setIsSubmitting(false)
  }

  return (
    <Dialog
      open={isOpen}
      onClose={isSubmitting ? undefined : handleClose}
      maxWidth="xs"
      fullWidth
      sx={{ '& .MuiPaper-root': { maxWidth: 480, px: 1.5, py: 2 } }}
    >
      <ProjectSaveItemContent
        isSubmitting={isSubmitting}
        onSubmit={onSubmit}
        onClose={handleClose}
      />
    </Dialog>
  )
}

export default memo(ProjectSaveItemDialog)
