import React, { Children, useEffect, useMemo, useState } from 'react'
import ClearIcon from '@mui/icons-material/Clear'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import SearchIcon from '@mui/icons-material/Search'
import {
  Box,
  Button,
  Input,
  InputAdornment,
  Menu,
  MenuItem,
  StyleProps,
  Typography,
  type BoxProps,
} from '@mui/material'
import { merge } from 'lodash'

import FTGTooltip, { Props as TooltipProps } from '@core/components/FTGTooltip'
import Tag from '@deliver/components/Shared/Tag'

const styles = {
  dropdown: {
    height: '32px',
    display: 'flex',
    '& button': {
      color: 'text.slateBlue',
      letterSpacing: '-0.13px',
      fontSize: '1rem',
      fontWeight: 400,
      padding: '2px 8px 2px 10px',
      lineHeight: '2rem',
      '&:hover': {
        background: 'inherit',
      },
      '& svg': {
        marginLeft: 1,
        color: 'text.slateBlue',
      },
      '& p': {
        width: '100%',
        textAlign: 'left',
      },
      '& span': {
        justifyContent: 'space-between',
      },
    },
  },
} satisfies StyleProps

type Option = {
  name?: React.ReactNode
  tooltip?: string
  tooltipMaxWidth?: number
  action?: () => void
  startAdornment?: React.ReactNode
  header?: boolean
  disabled?: boolean
  itemClassName?: string
  isInternal?: boolean
}

type Props = {
  testid?: string
  buttonLabel?: string | React.ReactNode
  value?: string | null
  placeholder: string
  searchPlaceholder?: string
  setValue: (value: any) => void
  options: Option[]
  hasClearButton?: boolean
  hasSearch?: boolean
  disabled?: boolean
  sx?: BoxProps['sx']
  menuSx?: BoxProps['sx']
  optionsSx?: BoxProps['sx']
  valueSx?: BoxProps['sx']
  Icon?: React.FC
  iconPosition?: 'left' | 'right'
  titleTooltip?: Partial<TooltipProps>
}

export default function FTGDropdown({
  testid,
  options = [],
  value,
  buttonLabel,
  setValue,
  placeholder,
  hasClearButton,
  hasSearch,
  searchPlaceholder,
  disabled,
  iconPosition = 'right',
  Icon = ExpandMoreIcon,
  sx,
  menuSx,
  valueSx,
  optionsSx,
  titleTooltip = {},
}: Props) {
  const [anchorEl, setAnchorEl] = useState(null)
  const [searchValue, setSearchValue] = useState('')

  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget)

  const handleMenuClose = () => setAnchorEl(null)

  const handleAction = (action) => () => {
    handleMenuClose()
    action()
  }

  const handleSelectItem = (option) => () => {
    handleMenuClose()
    if (value !== option.name) {
      setValue(option)
    }
  }

  useEffect(() => {
    if (value) {
      handleMenuClose()
    }
  }, [value])

  useEffect(() => {
    if (!disabled && options && options.length === 1) {
      const selectedOption = options[0]
      if (!selectedOption.disabled) handleSelectItem(selectedOption)()
    }
  }, [])

  const filteredOptions = useMemo(() => {
    if (!searchValue.trim()) {
      return options
    }
    return options.filter((option) =>
      String(option.name).toLowerCase().includes(searchValue.trim().toLowerCase()),
    )
  }, [options, searchValue])

  return (
    <Box aria-label="dropdown" sx={merge(sx, styles.dropdown)}>
      <FTGTooltip disabled={!titleTooltip} title="" {...titleTooltip}>
        <Button
          aria-controls={testid}
          aria-haspopup="true"
          onClick={handleMenuOpen}
          aria-label="Dropdown button"
          disabled={disabled}
          fullWidth
          sx={valueSx}
        >
          {iconPosition === 'left' && <Icon />}
          <Box
            component="span"
            flexGrow="1"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            textAlign="left"
          >
            {buttonLabel ?? value ?? placeholder}
          </Box>
          {iconPosition === 'right' && <Icon />}
        </Button>
      </FTGTooltip>

      <Menu
        id={testid}
        data-testid={testid}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        sx={menuSx}
      >
        {hasClearButton && (
          <MenuItem
            data-testid="dropdown-menu-item"
            onClick={handleSelectItem({ name: undefined })}
          >
            <ClearIcon /> Clear
          </MenuItem>
        )}
        {hasSearch && (
          <Input
            value={searchValue}
            onKeyDown={(e) => {
              if (!['Escape', 'ArrowDown', 'ArrowUp'].includes(e.key)) {
                e.stopPropagation()
              }
            }}
            sx={{
              width: { xs: 'calc(100% - 32px)' },
              minWidth: '320px',
              height: 37,
              ml: 2,
              mr: 2,
              px: 1,
              bgcolor: 'background.grey',
              borderRadius: 0.75,
              '& fieldset': { border: 'none' },
              typography: 'body2',
              border: '1px solid #E0E2E6',
              '& input::placeholder': {
                color: '#768297',
                opacity: 1,
              },
            }}
            inputProps={{ 'aria-label': searchPlaceholder }}
            placeholder={searchPlaceholder}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon fontSize="small" />
              </InputAdornment>
            }
            onChange={(e) => setSearchValue(e.target.value)}
          />
        )}
        {Children.toArray(
          filteredOptions.map((option) => {
            const { name, tooltip, tooltipMaxWidth, action, header, itemClassName, isInternal } =
              option
            return (
              <FTGTooltip
                title={tooltip}
                placement="left"
                disabled={!tooltip}
                wrap={option.disabled}
                maxWidth={tooltipMaxWidth || 300}
              >
                {header ? (
                  <Typography variant="h5">
                    {option.startAdornment}
                    {name}
                  </Typography>
                ) : (
                  <MenuItem
                    selected={value === name}
                    data-testid={`dropdown-menu-item-${name}`}
                    onClick={action ? handleAction(action) : handleSelectItem(option)}
                    disabled={option.disabled}
                    className={itemClassName}
                    sx={{ ...optionsSx, gap: 1 }}
                  >
                    <Box sx={{ display: 'inline-flex', alignItems: 'center', minWidth: 0 }}>
                      {option.startAdornment}
                      <Typography variant="inherit" noWrap>
                        {option.name}
                      </Typography>
                    </Box>

                    {isInternal && (
                      <Tag
                        sxOverride={{ cursor: 'pointer', textDecoration: 'none', ml: 'auto' }}
                        key={`model-internal-${name}`}
                        size="small"
                        tagColor="clone"
                        label="Internal"
                        data-testid="model-internal"
                        selected
                      />
                    )}
                  </MenuItem>
                )}
              </FTGTooltip>
            )
          }),
        )}
        {filteredOptions.length === 0 && (
          <MenuItem sx={{ ...optionsSx, gap: 1, my: 2 }} disabled>
            <Box sx={{ display: 'inline-flex', alignItems: 'center', minWidth: 0 }}>
              <Typography variant="inherit" noWrap>
                No models found
              </Typography>
            </Box>
          </MenuItem>
        )}
      </Menu>
    </Box>
  )
}
