import React, { useEffect, useMemo } from 'react'
import { Box, Button, SxProps } from '@mui/material'

import { FTGCheckbox } from '@core/components'
import FTGDropdown from '@core/components/FTGDropdown'
import FTGTooltip from '@core/components/FTGTooltip'
import { useAppSelector } from '@core/store'
import { depthOf, getAllLeafs, getMetadata, getOptionsFromTree, isChild } from '@core/utils/tree'

const styles: Record<string, SxProps> = {
  dropdown: {
    bgcolor: 'background.button',
    mt: 2,
    mb: 3,
    borderRadius: 0.2,
    '& button': {
      width: '100%',
      '&: hover': { background: 'transparent' },
      '& span': {
        justifyContent: 'space-between',
      },
    },
  },
  infoText: {
    fontWeight: 500,
  },
  infoTextItalic: {
    fontStyle: 'italic',
    fontWeight: 500,
  },
  passageCheckbox: {
    marginLeft: 1,
  },
}

interface Flavor {
  [key: string]: any
}
interface Metadata {
  [key: string]: any
  _placeholders?: string
}

export const compare = (a, b) => {
  let aComparator = a.name.toUpperCase()
  let bComparator = b.name.toUpperCase()

  if ('_order' in a && '_order' in b) {
    aComparator = a._order
    bComparator = b._order
  }

  if (aComparator < bComparator) {
    return -1
  }
  if (aComparator > bComparator) {
    return 1
  }
  return 0
}

export const checkRequired = (flavors, selected) => {
  const leafs = getAllLeafs(flavors, selected)

  const isAllRequired = leafs.every((option) => option._corpus_units && !option._units)

  if (isAllRequired) {
    return {
      required: true,
      disabled: true,
      reason: `This combination of Options requires a custom passage`,
    }
  }

  const isAllSelected = selected.filter(Boolean).length === selected.length

  if (!isAllSelected) {
    return {
      required: false,
      disabled: true,
      reason: 'You must select all options',
    }
  }

  const isSomeAllowed = leafs.some((option) => option._corpus_units && option._units)

  if (isSomeAllowed) {
    return { required: false, disabled: false, reason: '' }
  }

  return {
    required: false,
    disabled: true,
    reason: `This combination of options don't support a custom passage`,
  }
}

type FlavorDropdownType = {
  flavors: Flavor
  selectedFlavors: (string | undefined)[]
  setSelectedFlavors: (selectedFlavor: string[]) => void
  disabledFlavor: boolean
  name: string | undefined
}

export const FlavorsDropdown = ({
  flavors,
  selectedFlavors,
  setSelectedFlavors,
  disabledFlavor = false,
  name = '',
}: FlavorDropdownType) => {
  const depthOfFlavors = useMemo(() => depthOf(flavors), [flavors])
  const setValue = (index, option) => {
    let selected =
      selectedFlavors.length === depthOfFlavors
        ? [...selectedFlavors]
        : Array(depthOfFlavors).fill(undefined)

    if (index === 0 && option.name) {
      selected = selected.map((flavorName, idx) => {
        if (idx === 0) return option.name

        return isChild(flavors[option.name], flavorName, idx - 1) ? flavorName : undefined
      })
    } else {
      selected[index] = option.name
    }

    setSelectedFlavors(selected)
  }

  const flavorsOptions = useMemo(() => {
    const result: Flavor[][] = []

    for (let depth = 0; depth < depthOfFlavors; depth += 1) {
      const r = getOptionsFromTree({ flavors, depth, selectedFlavors }) as Flavor[]
      result.push(r)
    }

    return result.map((options) => options.sort(compare))
  }, [flavors, selectedFlavors, depthOfFlavors])

  const placeholders = useMemo(
    () => (getMetadata(flavors, 0) as Metadata)?._placeholders,
    [flavors],
  )

  return (
    <Box>
      {flavorsOptions.map((options, index) => {
        const placeholder =
          (Array.isArray(placeholders) && placeholders[index]) || 'Select Generate Model'
        return (
          <FTGDropdown
            key={`select-${index}-${name}`}
            sx={styles.dropdown}
            value={selectedFlavors[index]}
            setValue={(o) => setValue(index, o)}
            buttonLabel={selectedFlavors[index]}
            placeholder={placeholder}
            options={options}
            hasClearButton
            disabled={disabledFlavor}
          />
        )
      })}
    </Box>
  )
}

type FlavorsSelectType = {
  modelName?: string | undefined
  flavors: Flavor
  selectedFlavors: (string | undefined)[]
  setSelectedFlavors: (selectedFlavor: string[]) => void
  customPassageEnabled?: boolean
  setCustomPassageEnabled: (isCustomPassageEnabled: boolean) => void
  disabledFlavor: boolean
}

const FlavorsSelect = ({
  modelName = '',
  flavors,
  selectedFlavors,
  setSelectedFlavors,
  customPassageEnabled = false,
  setCustomPassageEnabled,
  disabledFlavor,
}: FlavorsSelectType) => {
  const depthOfFlavors = useMemo(() => depthOf(flavors), [flavors])
  const aiModelInputs = useAppSelector((state) => state.author.selectedModel?.inputSettings) || []

  useEffect(() => {
    if (!selectedFlavors.length) {
      setSelectedFlavors(Array(depthOfFlavors).fill(undefined))
    }
  }, [selectedFlavors])

  const { required, disabled, reason } = useMemo(
    () => checkRequired(flavors, selectedFlavors),
    [flavors, selectedFlavors],
  )

  useEffect(() => {
    if (required && !customPassageEnabled) setCustomPassageEnabled(true)
    if (!required && reason) setCustomPassageEnabled(false)
  }, [reason, required, customPassageEnabled])

  const clearSelectedDisabled =
    disabledFlavor || !selectedFlavors.some((flavor) => flavor !== undefined)

  return (
    <>
      <FlavorsDropdown
        disabledFlavor={disabledFlavor}
        flavors={flavors}
        selectedFlavors={selectedFlavors}
        setSelectedFlavors={setSelectedFlavors}
        name={modelName}
      />
      {modelName && (
        <div>
          <FTGTooltip title="This will clear selected sub models." disabled={clearSelectedDisabled}>
            <Box sx={{ textAlign: 'right' }}>
              <Button
                variant="text"
                onClick={() => setSelectedFlavors(Array(depthOfFlavors).fill(undefined))}
                disabled={clearSelectedDisabled}
                color="secondary"
              >
                Clear Selected
              </Button>
            </Box>
          </FTGTooltip>
        </div>
      )}

      {!(aiModelInputs?.length > 0) && (
        <FTGTooltip title={reason}>
          <div>
            <FTGCheckbox
              sx={styles.passageCheckbox}
              label="I want to submit a custom passage"
              onChange={(e) => setCustomPassageEnabled(e.target.checked)}
              checked={customPassageEnabled}
              disabled={disabled}
            />
          </div>
        </FTGTooltip>
      )}
    </>
  )
}

export default FlavorsSelect
