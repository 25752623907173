import React from 'react'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import { Box, Checkbox, StyleProps, SxProps } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'

const styles = {
  checkBoxOutlineBlankIconRoot: {
    color: 'secondary.light',
  },
  checkBoxIconRoot: {
    color: 'secondary.dark',
  },
  checkBoxLabel: {
    fontSize: '0.875rem',
    fontWeight: 500,
  },
} satisfies StyleProps

interface FTGCheckboxProps {
  label?: string
  classes?: string
  onChange?: (HTMLInputElement, boolean) => void
  checked?: boolean
  disabled?: boolean
  sx?: SxProps
}

const FTGCheckbox = ({
  label = '',
  classes = '',
  onChange = () => {},
  checked = false,
  disabled = false,
  sx = {},
}: FTGCheckboxProps) => {
  return (
    <Box className={classes} sx={sx}>
      <FormControlLabel
        sx={styles.checkBoxLabel}
        label={label}
        control={
          <Checkbox
            disabled={disabled}
            checked={checked}
            onChange={onChange}
            icon={
              <CheckBoxOutlineBlankIcon sx={styles.checkBoxOutlineBlankIconRoot} fontSize="small" />
            }
            checkedIcon={<CheckBoxIcon sx={styles.checkBoxIconRoot} fontSize="small" />}
          />
        }
      />
    </Box>
  )
}

export default FTGCheckbox
