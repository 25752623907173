import React from 'react'
import AddIcon from '@mui/icons-material/Add'
import { AppBar, Box, Button, Container } from '@mui/material'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import { checkRequired } from '@author/components/ModelSidebar/FlavorsSelect/flavors-select'
import UserProfile from '@core/components/User/Profile'
import { HEADER_HEIGHT } from '@core/constants/styles'
import { useContentVersionState } from '@core/content-version'
import { useMainState } from '@core/main-state'
import { useAppDispatch } from '@core/store'
import { useAiModels } from '@pages/author/author-api'
import { actions, authorClear } from '@pages/author/author-slice'
import type { Item2 as Item } from '@pages/author/author-types'
import { useBatches } from '@pages/author/batches/batches-state'

import FTGTooltip from '../FTGTooltip'
import Logo from './Logo'
import Tabs from './Tabs'

const buildGenerateTab = (aiModels: ReturnType<typeof useAiModels>['data'], itemId: string) => {
  const defaultActionResult = actions.set({
    selectedTab: 0,
    selectedModel: null,
    selectedFlavors: [],
    customPassageEnabled: false,
    customPassage: '',
  })

  try {
    const models = aiModels || []
    const item = useContentVersionState.getState().instances[itemId]?.items.root as unknown as Item

    if (!item) {
      console.warn('item not found to build the generate tab', itemId)
      return
    }

    const { flavors, internalName } = item.aiModel

    const model = models.find((m) => m.internalModelName === internalName)

    if (!model) {
      return defaultActionResult
    }

    const submodels = item.currentContent?.submodels || []
    const customPassage = item.currentContent?.content?.custom_passage || ''
    const { required } = checkRequired(flavors, submodels)

    return actions.set({
      selectedTab: 0,
      selectedModel: model,
      selectedFlavors: submodels,
      customPassageEnabled: required || Boolean(customPassage),
      customPassage,
    })
  } catch (error) {
    console.error('Error buildGenerateTab', error)
    return defaultActionResult
  }
}

const Header = () => {
  const dispatch = useAppDispatch()
  const [hasCustomer] = useMainState((state) => [Boolean(state.customer)])

  const location = useLocation()
  const navigate = useNavigate()
  const aiModelsQuery = useAiModels()

  // I didn't find the right type
  const handleClickCreate = (e: any) => {
    e.preventDefault()

    if (location.pathname.startsWith('/author/item')) {
      const actionResult = buildGenerateTab(aiModelsQuery.data, location.pathname.split('/')[3])

      if (actionResult) {
        dispatch(actionResult)
      }
    }

    dispatch(actions.set({ selectedTab: 0 }))
    useBatches.setState({ selectedBatchId: null })

    navigate('/author')
  }

  const handleClear = () => {
    dispatch(authorClear())
    useBatches.setState({ selectedBatchId: null })
  }

  const createButton = (
    <FTGTooltip title="Get new items" disabled={location.pathname === '/author'} placement="right">
      <Button
        size="small"
        color="secondary"
        variant="contained"
        to="/author"
        component={Link}
        onClick={handleClickCreate}
        disabled={location.pathname === '/author' || !aiModelsQuery.data}
        aria-label="Generate new item(s)"
        sx={{
          display: { xs: 'none', md: 'flex' },
        }}
      >
        <AddIcon sx={{ mr: 0.5, fontSize: 20 }} />
        Create
      </Button>
    </FTGTooltip>
  )

  return (
    <>
      <AppBar
        component="div"
        sx={{
          bgcolor: 'common.white',
          boxShadow: '0 8px 16px -4px rgba(9, 30, 66, 0.25), 0 0 1px 0 rgba(9, 30, 66, 0.31)',
          height: HEADER_HEIGHT,
          minWidth: '100%',
        }}
      >
        <Container sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Logo onClear={handleClear} />

          <Box mr="auto" display="flex" alignItems="center">
            {hasCustomer && <Tabs onClear={handleClear} />}
          </Box>

          <Box
            sx={{
              width: 390,
              justifyContent: { xs: 'flex-end', md: hasCustomer ? 'space-between' : 'flex-end' },
              alignItems: 'center',
              display: 'flex',
            }}
          >
            {hasCustomer && createButton}

            <UserProfile />
          </Box>
        </Container>
      </AppBar>
      <Box height={HEADER_HEIGHT} />
    </>
  )
}

export default Header
