import React from 'react'
import { Badge, Box } from '@mui/material'

import * as queries from './export-queries'

type ExportHeaderProps = {
  job?: queries.ExportJobBasic
  showBadge?: boolean
  badgeColor?: 'error' | 'warning'
}

const getLabel = (jobBasic: queries.ExportJobBasic) => {
  return `${jobBasic.exportType}.${jobBasic.fileName.split('.')[1]}`
}

export const ExportHeader = (props: ExportHeaderProps) => {
  const [tooltip, date] = React.useMemo(() => {
    if (!props.job?.createdAt) return ['', '']

    const language = navigator?.language || 'en-US'
    const dateInstance = new Date(props.job.createdAt)

    return [
      dateInstance.toLocaleDateString(language, {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      }),
      dateInstance.toLocaleDateString(language, {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      }),
    ]
  }, [props.job?.createdAt])

  if (!props.job) return null

  return (
    <Box flexGrow={1} display="flex" alignItems="center">
      <Badge
        variant="dot"
        color={props.badgeColor}
        invisible={!props.showBadge}
        sx={{ mr: 3, pr: 1 }}
        arial-label={`export-job-status-${props.job.status}`}
      >
        <Box>{getLabel(props.job)}</Box>
      </Badge>
      <Box title={tooltip}>{date}</Box>
    </Box>
  )
}
