import React from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import Alert from '@core/components/alert/alert'
import { Button } from '@core/components/button/button'
import { useMainState } from '@core/main-state'

/**
 * Displays an alert when the user's login session is about to expire
 * @constructor
 */
export const UserSessionWarning = () => {
  const { userSessionWarningVisible } = useMainState()
  const { logout } = useAuth0()

  return userSessionWarningVisible ? (
    <Alert
      severity="warning"
      action={
        <Button
          label="Login"
          color="inherit"
          size="small"
          aria-label="Login again to refresh your session"
          onClick={async () => {
            await logout()
          }}
        />
      }
      sx={{ position: 'absolute', width: '100vw', zIndex: 1001 }}
      square
    >
      Your session will expire soon. Please login again to continue.
    </Alert>
  ) : null
}
