import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Auth0Provider } from '@auth0/auth0-react'
import { USER_LOGIN_TIME } from '@core/constants'
import env from '@core/env'

export default function Auth0ProviderWithHistory(props: { children: React.ReactElement }) {
  const navigate = useNavigate()

  return (
    <Auth0Provider
      domain={env.AUTH0_DOMAIN}
      clientId={env.AUTH0_CLIENT_ID}
      onRedirectCallback={(appState) => {
        // see main-idle-tasks.tsx for more info about this storage.
        window.localStorage.setItem(USER_LOGIN_TIME, Date.now().toString())

        const returnTo = appState?.returnTo

        if (returnTo) {
          // this is a hack to avoid the page being redirected to /author by inner components,
          // which are executed first, so it needs to run after the exection pool finishes
          setTimeout(() => {
            navigate(returnTo, {
              replace: true,
              unstable_flushSync: true,
            })
          }, 1)
        }
      }}
      useRefreshTokens
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: env.AUTH0_AUDIENCE,
        scope: 'openid profile email offline_access',
      }}
      cacheLocation="localstorage"
    >
      {props.children}
    </Auth0Provider>
  )
}
