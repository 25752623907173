/* eslint-disable no-console */
import { generatePath } from 'react-router-dom'

import { actions as commentsActions } from '@containers/comments/comments-slice'
import { actions as metadataActions } from '@containers/metadata/metadata-slice'
import ErrorHandler from '@core/api/ErrorHandler'
import { CLOZE, ITEM_SET } from '@core/constants/content-type'
import { AUTHOR_ITEM_ROUTE } from '@core/constants/routes'
import { getUserRole } from '@core/main-state'
import { addSnack } from '@core/snack/snack-state'
import { createAsyncThunk } from '@reduxjs/toolkit'

import { Item, ItemMap, ItemRoot, StateLoaded } from './author-cloze-types'
import * as utils from './author-cloze-utils'
import * as queries from './cloze-queries'

/**
 * Fetch initial item data
 */
export const fetchItemSet = createAsyncThunk<Partial<StateLoaded>, string>(
  'authorCloze/fetchItemSet',
  async (id, { extra, dispatch }) => {
    try {
      const { role } = getUserRole()

      const { data } = await extra.client.query({
        fetchPolicy: 'network-only',
        query: queries.GET_ITEM_SET,
        variables: { id },
        context: { role },
      })

      if (!data.item) {
        extra.navigate('/404')
        return { notFound: true }
      }

      const { children, root } = utils.normalizeItemSet(data.item)
      const items = { ...children, root } as ItemMap

      if (root.aiModel.type !== ITEM_SET) {
        const path = generatePath(AUTHOR_ITEM_ROUTE, { itemId: id })
        extra.navigate(path, { replace: true })
        return {}
      }

      dispatch(
        metadataActions.setInitialValues({
          itemId: root.id,
          originalSubmodels: root.originalContent.submodels,
          submodels: root.currentContent.submodels,
          isCloze: true,
          externalMetadata: root.currentContent.externalMetadata,
        }),
      )

      const { cloze_ids: clozeIds = [], mcq_ids: mcqIds = [] } = root.currentContent.content

      let startIndex = 1

      for (const itemId of [...mcqIds, ...clozeIds]) {
        const item = items[itemId]

        if (!item) {
          console.error('item not found', itemId)
          continue
        }

        items[itemId] = { ...item, index: startIndex }
        startIndex += 1
      }

      if (items) {
        dispatch(
          commentsActions.set({
            itemId: items.root.id,
            comments: items.root.currentContent.comments,
          }),
        )
      }

      return { initialized: true, items }
    } catch (error) {
      ErrorHandler(error)

      addSnack({
        message: `Error while fetching the item. ${error.message}`,
        severity: 'error',
      })

      throw error
    }
  },
)

/**
 * Generate answers for item
 */
export const regenerateItem = createAsyncThunk<
  void,
  { itemId: string; clearStem?: boolean; clearOptions?: boolean }
>(
  'authorCloze/regenerateItem',
  async ({ itemId, clearStem = false, clearOptions = false }, { extra }) => {
    try {
      const { role } = getUserRole()
      await extra.client.mutate({
        mutation: queries.REGENERATE_ITEM,
        variables: { itemId, clearStem, clearOptions },
        context: { role },
      })
    } catch (error) {
      ErrorHandler(error)

      addSnack({
        message: `Error while regenerating ${error.message}`,
        severity: 'error',
      })

      throw error
    }
  },
)

/**
 * Generate answers for item
 */
interface GenerateParams {
  itemId?: string
  empty?: boolean
  aiModelId?: string
  rootId?: string
  flavors?: string[]
}

export const generateItem = createAsyncThunk(
  'authorCloze/generateItem',
  async ({ empty, itemId }: GenerateParams, { extra, getState }) => {
    const { items } = getState().authorCloze
    const { submodels } = getState().metadata
    if (!items || !items.root) return

    let currSubmodels = items.root.job.selectedSubmodels
    const noSubmodelSelect = !currSubmodels.filter(Boolean).length
    if (noSubmodelSelect && submodels.length) {
      currSubmodels = submodels
    }

    const variables: GenerateParams = {
      empty,
      itemId,
      flavors: currSubmodels,
    }
    const clozeItem = Object.values(items).find((i) => i.type === CLOZE)

    variables.aiModelId = clozeItem?.aiModelId

    if (empty) {
      variables.rootId = items.root.id
    }

    try {
      const { data } = await extra.client.mutate({
        mutation: queries.GENERATE_ITEM,
        variables,
      })

      return data.generate
    } catch (error) {
      ErrorHandler(error)

      addSnack({
        message: `Error while generating ${error.message}`,
        severity: 'error',
      })

      throw error
    }
  },
)

/**
 * Save/deliver an item
 */
export const saveItem = createAsyncThunk<
  ItemRoot,
  { itemId: string; projectId: string; projectName: string }
>(
  'authorCloze/saveItem',
  async ({ itemId, projectId, projectName }, { extra, getState, dispatch }) => {
    try {
      const { role } = getUserRole()
      const { data } = await extra.client.mutate({
        mutation: queries.SAVE_ITEM,
        variables: { id: itemId, projectId },
        context: { role },
      })

      addSnack({
        message: `Your item has been saved to the project ${
          projectName || getState().authorCloze.items?.root.project?.name
        } in Deliver`,
        severity: 'success',
      })

      dispatch(fetchItemSet(itemId))

      return data.item
    } catch (error) {
      ErrorHandler(error)

      addSnack({
        message: `Error saving item ${error.message}`,
        severity: 'error',
      })

      throw error
    }
  },
)

export const updateRootContent = createAsyncThunk<void, { html: string; emptyItemId: string }>(
  'authorCloze/updateRootContent',
  async ({ html, emptyItemId }, { extra, getState }) => {
    try {
      const { root } = getState().authorCloze.items as ItemMap
      const { submodels } = getState().metadata
      const ids = new Set(root.currentContent.content.cloze_ids)
      ids.add(emptyItemId)
      const hasSubmodel = root.currentContent.submodels?.filter(Boolean).length

      await extra.client.mutate({
        mutation: queries.UPDATE_ITEM_ROOT,
        variables: {
          id: root.id,
          content: { ...root.currentContent.content, stimulus: html, cloze_ids: Array.from(ids) },
          qualityMetrics: root.currentContent.qualityMetrics || [],
          submodels: hasSubmodel ? root.currentContent.submodels : submodels,
        },
      })
    } catch (error) {
      ErrorHandler(error)

      addSnack({
        message: `Error updating item: ${error.message}`,
        severity: 'error',
      })

      throw error
    }
  },
)

/**
 * Update draft items
 */
export const updateItemsContent = createAsyncThunk<void, string[]>(
  'authorCloze/updateItemsContent',
  async (keys, { extra, getState }) => {
    const itemsMap = getState().authorCloze.items as ItemMap
    const { submodels } = getState().metadata
    if (!itemsMap || !keys.length) return
    const promises: Promise<any>[] = []

    for (const key of keys) {
      if (key === 'root') {
        const item = itemsMap.root
        const hasSubmodel = item.currentContent.submodels?.filter(Boolean).length

        promises.push(
          extra.client.mutate({
            mutation: queries.UPDATE_ITEM_ROOT,
            variables: {
              id: item.id,
              content: item.currentContent.content,
              qualityMetrics: item.currentContent.qualityMetrics || [],
              submodels: hasSubmodel ? item.currentContent.submodels : submodels,
            },
          }),
        )
      } else {
        const item = itemsMap[key]
        const hasSubmodel = item.currentContent.submodels.filter(Boolean).length

        promises.push(
          extra.client.mutate({
            mutation: queries.UPDATE_ITEM_CONTENT,
            variables: {
              id: item.id,
              content: item.currentContent.content,
              submodels: hasSubmodel ? item.currentContent.submodels : submodels,
            },
          }),
        )
      }
    }

    try {
      await Promise.all(promises)
    } catch (error) {
      ErrorHandler(error)

      addSnack({
        message: `Error while updating an item ${error.message}`,
        severity: 'error',
      })

      throw error
    }
  },
)

/**
 * Regenerate root question
 */
export const regenerateRoot = createAsyncThunk(
  'authorCloze/regenerateRoot',
  async (_, { extra, getState }) => {
    try {
      const { items } = getState().authorCloze
      if (!items) return
      const clozeItem = Object.values(items).find((i) => i.type === CLOZE)

      await extra.client.mutate({
        mutation: queries.REGENERATE_ROOT_QUESTION,
        variables: { itemId: clozeItem?.id, aiModelId: clozeItem?.aiModelId },
      })
    } catch (error) {
      ErrorHandler(error)

      addSnack({
        message: `Error while regenerating the response letter: ${error.message}`,
        severity: 'error',
      })

      throw error
    }
  },
)

/**
 * Refetch an item content after some status change
 */

/**
 * Refetch an item root content after some status change
 * If the response do not include the stimulus, wait some time and try again
 */
interface FetchItemContentParams {
  isRoot: boolean
  id: string
}

const wait = (t: number) => new Promise((r) => setTimeout(r, t))

async function tryFetchRoot(makeRequest, n = 1) {
  console.log('try fetch ->', n)
  if (n > 20) {
    throw new Error('Could not fetch root stimulus')
  }

  const { data } = await makeRequest()

  if (!data.item.currentContent[0].content.stimulus) {
    await wait(200 * n)
    return tryFetchRoot(makeRequest, n + 1)
  }
  const rootItem: ItemRoot = {
    ...data.item,
    currentContent: {
      content: data.item.currentContent?.[0]?.content,
      submodels: data.item.currentContent?.[0]?.submodels,
      qualityMetrics: data.item.currentContent?.[0]?.qualityMetrics,
    },
    savedContent: {
      content: data.item.savedContent?.[0]?.content,
    },
    originalContent: {
      submodels: data.item.originalContent?.[0]?.submodels,
    },
  }
  return rootItem
}

export const fetchItemContent = createAsyncThunk<Item, FetchItemContentParams>(
  'authorCloze/fetchItemContent',
  async ({ isRoot, id }, { extra }) => {
    const { role } = getUserRole()
    try {
      const makeRequest = () =>
        extra.client.query({
          fetchPolicy: 'network-only',
          query: queries.GET_ITEM_CONTENT,
          variables: { id },
          context: { role },
        })

      if (isRoot) return tryFetchRoot(makeRequest)

      const { data } = await makeRequest()

      const item = {
        ...data.item,
        currentContent: {
          content: data.item.currentContent?.[0]?.content,
          submodels: data.item.currentContent?.[0]?.submodels,
          qualityMetrics: data.item.currentContent?.[0]?.qualityMetrics,
        },
        savedContent: {
          content: data.item.savedContent?.[0]?.content,
        },
        originalContent: {
          submodels: data.item.originalContent?.[0]?.submodels,
        },
      }
      return item
    } catch (error) {
      ErrorHandler(error)

      addSnack({
        message: `Error while fetching an item ${error.message}`,
        severity: 'error',
      })

      throw error
    }
  },
)
