/* eslint-disable no-param-reassign */
import { current } from 'immer'
import { immer } from 'zustand/middleware/immer'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

import { ItemStatusMap, type ItemStatus } from '@core/types'

type Item = {
  id: string
  status: ItemStatus
  updatedAt: string
}

type ExtendedItem = Item & { limitReached?: boolean }

type Items = {
  [childId: string]: ExtendedItem
}

type OnCompleteCallback = (item: Item, prev: Item) => Promise<Partial<Item>>

type RunCallbackArgs = {
  cb: OnCompleteCallback
  rootId: string
  item: Item
  prev: Item
}

export type InitializeOptions = {
  onChange?: (item: Item, prev: Item) => void
  onFail: OnCompleteCallback
  onDone: OnCompleteCallback
  refetchItem: (item: Item) => Promise<Item>
  onTimeout?: (item: Item, newStatus: ItemStatus) => void
}

export type Instance = {
  rootId: string
  items: Items
  options: InitializeOptions
  timers: { [id: string]: NodeJS.Timeout }
}

type State = {
  instances: Record<string, Instance>
}

type Actions = {
  init: (rootId: string, items: Item[], options: InitializeOptions) => void
  onUpdate: (rootId: string, items: Item[]) => void
  overrideItem: (rootId: string, id: string, item: Partial<Item>) => void
  unmount: (rootId: string) => void
  reset: () => void
}

const initialState: State = {
  instances: {},
}

const limits = {
  [ItemStatusMap.IN_PROGRESS]: 60,
  // it would be strange to have it not started for more than few seconds
  [ItemStatusMap.NOT_STARTED]: 30,
  [ItemStatusMap.RATIONALES]: 60,
  [ItemStatusMap.POSSIBLE_KEYS]: 60,
}

const LOOP_IN_PROGRESS_TIMEOUT = 500

export const getStatusChange = (item: Item, prev: Item) => {
  const turnedDone = prev.status !== ItemStatusMap.DONE && item.status === ItemStatusMap.DONE

  const turnedFailed =
    !turnedDone && prev.status !== ItemStatusMap.FAILED && item.status === ItemStatusMap.FAILED

  const turnedInProgress = Boolean(limits[item.status]) && !limits[prev.status]

  return {
    equal: prev.status === item.status,
    turnedDone,
    turnedFailed,
    turnedInProgress,
    finished: item.status === ItemStatusMap.DONE || item.status === ItemStatusMap.FAILED,
  }
}

const clearTimers = (instance: Instance) => {
  Object.values(instance.timers).forEach((timer) => clearTimeout(timer))
}

/* eslint-disable max-len */
/**
  Controls the status of an item and its children and calls necessary actions when the status changes

  The problem:
    We execute asynchronous actions and rely on subscriptions to track transitions in the item status.
    When the status transition happens, the UI should call the action to refetch the item and its new content.
    However, if, for some reason, the subscription is not triggered, or the order of events gets out of sync,
    the UI will not be able to display the item correctly.

  The solution:
    If the item starts in progress, or some user action changes the status to in progress, a function loop is started.
    The loop checks the item status every half second.
    If the item status is still in progress, the loop continues.
    If the loop reaches the limit (30s and 60s), the loop stops and the timeout message is displayed.
    Every 4 loops (2 seconds), the loop executes a refetch of the item. This will ensure that we do not miss any status change.
    If the item status is not in progress anymore, the loop stops.
 */
/* eslint-enable max-len */
export const useItemWatchStatus = createWithEqualityFn(
  immer<State & Actions>((set, get) => {
    const setInstance = (rootId: string, cb: (instance: Instance) => void) => {
      set((prev) => {
        const instance = prev.instances[rootId]

        if (!instance) return

        cb.apply(null, [instance])
      })
    }

    const refetchItem = async (instance: Instance, prevItem: Item) => {
      const { options } = instance
      const item = await options.refetchItem(prevItem)

      const { finished } = getStatusChange(item, prevItem)

      if (finished) {
        setInstance(instance.rootId, (prevInstance) => {
          prevInstance.items[item.id].status = item.status
          prevInstance.items[item.id].updatedAt = item.updatedAt

          delete prevInstance.timers[item.id]
        })
      }

      return finished
    }

    // every half second, check if the item is still in progress
    // if so, keep looping until it is done - or until the limit is reached
    const loopInProgressCheck = async (
      rootId: string,
      id: string,
      limit: number,
      count = 0,
    ): Promise<void> => {
      const instance = get().instances[rootId]
      const item = instance?.items[id]

      if (!instance || !item) {
        console.warn('useItemWatchStatus.loopInProgressCheck - item not found', { rootId, id })
        return
      }

      const isInProgress = Boolean(limits[item.status])

      // cleanup
      if (!isInProgress) {
        setInstance(rootId, (prevInstance) => {
          clearTimeout(prevInstance.timers[id])
          delete prevInstance.timers[id]
        })

        return
      }

      const elapsedSeconds =
        Math.abs(new Date().getTime() - new Date(item.updatedAt).getTime()) / 1000

      // every ~2 seconds, refetch the item
      if (count > 0 && count % 4 === 0) {
        const isFinished = await refetchItem(instance, item)

        if (isFinished) {
          return
        }
      }

      if (elapsedSeconds > limit) {
        console.warn(
          `Task is taking longer than expected.
          itemId: ${item.id}
          Status: ${item.status} - time: ${elapsedSeconds}/${limit}`,
        )

        // if the status was something from the default generate pipeline, change it to FAILED
        let newStatus: ItemStatus = ItemStatusMap.FAILED

        // if the status was possible keys or rationales, rollback the item status to DONE
        // this is to avoid an infinite loading
        if (
          item.status === ItemStatusMap.POSSIBLE_KEYS ||
          item.status === ItemStatusMap.RATIONALES
        ) {
          newStatus = ItemStatusMap.DONE
        }

        setInstance(rootId, (prevInstance) => {
          prevInstance.items[id].limitReached = true
          prevInstance.items[id].status = newStatus
          delete prevInstance.timers[id]
        })

        instance.options.onTimeout?.(item, newStatus)

        return
      }

      setInstance(rootId, (prevInstance) => {
        prevInstance.timers[id] = setTimeout(
          () => loopInProgressCheck(rootId, id, limit, count + 1),
          LOOP_IN_PROGRESS_TIMEOUT,
        )
      })
    }

    // after running the callback the status could be different for some unknown reason
    const runCallbackAndCommit = async ({ cb, rootId, item, prev }: RunCallbackArgs) => {
      try {
        const result = await cb(item, prev)

        setInstance(rootId, (prevInstance) => {
          prevInstance.items[item.id] = {
            ...item,
            ...result,
          }
        })
      } catch (error) {
        console.error('useItemWatchStatus: error while running callback', error)
        setInstance(rootId, (prevInstance) => {
          prevInstance.items[item.id] = item
        })
      }
    }

    return {
      ...initialState,

      init(rootId, items, options) {
        const instanceItems: Items = {}
        const timers: Instance['timers'] = {}

        const prevInstance = get().instances[rootId]

        if (prevInstance) {
          clearTimers(prevInstance)
        }

        for (const item of items) {
          // if the item starts in progress, it should activate the timeout
          const limit = limits[item.status]
          const isInProgress = Boolean(limit)

          if (isInProgress) {
            timers[item.id] = setTimeout(
              () => loopInProgressCheck(rootId, item.id, limit),
              LOOP_IN_PROGRESS_TIMEOUT,
            )
          }

          instanceItems[item.id] = {
            id: item.id,
            updatedAt: item.updatedAt,
            status: item.status,
          }
        }

        set((prev) => {
          prev.instances[rootId] = { items: instanceItems, rootId, options, timers }
        })
      },

      onUpdate(rootId, items) {
        setInstance(rootId, (instance) => {
          for (const item of items) {
            const itemId = item.id === 'root' ? rootId : item.id

            const prevValue = current(instance.items[itemId])

            if (!prevValue) {
              continue
            }

            if (prevValue.status === item.status) {
              instance.items[itemId] = item
              continue
            }

            const { turnedDone, turnedFailed, turnedInProgress } = getStatusChange(item, prevValue)

            if (turnedInProgress) {
              instance.timers[itemId] = setTimeout(
                () => loopInProgressCheck(rootId, itemId, limits[item.status]),
                LOOP_IN_PROGRESS_TIMEOUT,
              )
            }

            if ((turnedDone || turnedFailed) && instance.timers[itemId]) {
              clearTimeout(instance.timers[itemId])
              delete instance.timers[itemId]
            }

            if (turnedFailed) {
              runCallbackAndCommit({ cb: instance.options.onFail, rootId, item, prev: prevValue })
              continue
            }

            if (turnedDone) {
              runCallbackAndCommit({ cb: instance.options.onDone, rootId, item, prev: prevValue })
              continue
            }

            instance.items[itemId] = item
            instance.options.onChange?.(item, prevValue)
          }
        })
      },

      overrideItem(rootId, id, item) {
        setInstance(rootId, (instance) => {
          const oldItem = instance.items[id]

          instance.items[id] = { ...instance.items[id], ...item, id }

          clearTimeout(instance.timers[id])

          instance.options.onChange?.(instance.items[id], oldItem)
        })
      },

      unmount(rootId) {
        set((prev) => {
          const instance = prev.instances[rootId]

          if (!instance) return

          clearTimers(instance)

          delete prev.instances[rootId]
        })
      },

      reset() {
        // used only in tests, clear all pending timers
        Object.values(get().instances).forEach((instance) => {
          clearTimers(instance)
        })

        set(initialState)
      },
    }
  }),
  shallow,
)

// ---------------------------------------------------------------------------

export const useItemStatus = (rootId: string, id: string): ExtendedItem => {
  return useItemWatchStatus((state) => {
    const instance = state.instances[rootId]

    if (!instance) {
      throw new Error(`useStatus: instance ${rootId} not found`)
    }

    const item = instance.items[id]

    if (!item) {
      throw new Error(`useStatus: item ${id} not found`)
    }

    return item
  })
}
