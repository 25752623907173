// eslint-disable-next-line no-restricted-imports
import * as generated from '@core/api/generated/services.gen'

const excluded = [
  'client',
  'continueExportJob',
  'createAiModel',
  'deleteAiModels',
  'deleteAuth0Users',
  'deleteFinetunes',
  'downloadItems',
  'getItemMedia',
  'getItemMediaList',
  'healthz',
  'importFinetunes',
  'loginCallback',
  'loginCallback1',
  'getIntegration',
  'resetCustomerData',
  'resetUserData',
  'testIntegration',
  'upsertIntegration',
  'uploadMediaToItem',
] as const

type Excluded = (typeof excluded)[number]

// BffRoutes but without the excluded
export type BffRoutesAvailable = Exclude<keyof typeof generated, Excluded>

export const bffOptions = Object.keys(generated).filter(
  (key) => !excluded.includes(key as Excluded),
) as BffRoutesAvailable[]

export type BffFeatureFlags = {
  [key in BffRoutesAvailable]?: boolean
}
