import React from 'react'
import ErrorIcon from '@mui/icons-material/Error'
import WarningIcon from '@mui/icons-material/Warning'
import { Badge, CircularProgress } from '@mui/material'

import CheckIcon from '@assets/images/icon-circle-check-24.svg?react'

type StatusIconProps = {
  status: string
}

const StatusIcon = ({ status }: StatusIconProps) => {
  if (status === 'pending' || status === 'in_progress' || !status) {
    return <CircularProgress color="secondary" size={20} aria-label="loading download" />
  }

  if (status === 'failed') {
    return <ErrorIcon sx={{ color: 'error.main' }} aria-label="failed download" />
  }

  if (status === 'completed_with_issues') {
    return (
      <Badge variant="dot" color="error" invisible={false}>
        <CheckIcon sx={{ color: 'other.checkedGreen' }} />
      </Badge>
    )
  }

  if (status === 'waiting_user_input') {
    return <WarningIcon sx={{ color: 'warning.main' }} />
  }

  return <CheckIcon sx={{ color: 'other.checkedGreen' }} />
}

export default React.memo(StatusIcon)
