import { useApi } from '@core/api'
import { getClient } from '@core/api/api-state'
import { minute } from '@core/constants/timeouts'
import { getUserRole, useBffEnabled } from '@core/main-state'
import { useQuery } from '@tanstack/react-query'

import * as gqlQueries from './author-queries'
import type { AiModel } from './author-types'

export const authorKeys = {
  aiModelList: ['author', 'ai-models'],
}

// @TODO:
// possible improvement to add option to `select` certain fields
// see https://tanstack.com/query/latest/docs/framework/react/reference/useQuery
//    then find "select: (data: TData) => unknown"
export const useAiModels = () => {
  const api = useApi()
  const bffEnabled = useBffEnabled()

  return useQuery({
    queryKey: authorKeys.aiModelList,
    queryFn: async () => {
      if (!bffEnabled.listAiModels) {
        const client = getClient()
        const { role } = getUserRole()

        const { data } = await client.query({
          query: gqlQueries.GET_MODELS,
          context: { role },
          fetchPolicy: 'network-only',
        })

        return data.models as unknown as AiModel[]
      }

      const response = await api.listAiModels({ throwOnError: true })

      return response.data as unknown as AiModel[]
    },
    staleTime: minute * 5,
  })
}
