import React from 'react'
import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  IconButton as MuiIconButton,
  IconButtonProps,
} from '@mui/material'

export interface ButtonProps extends MuiButtonProps {
  label: string
}

export const Button = ({ label, ...rest }: ButtonProps) => {
  return <MuiButton {...rest}>{label}</MuiButton>
}

export const IconButton = (props: IconButtonProps) => {
  return <MuiIconButton {...props} />
}
