export function get(key: string, defaultValue: any) {
  const stored = sessionStorage.getItem(key)
  if (!stored) {
    return defaultValue
  }
  return JSON.parse(stored)
}

export function set(key: string, value: any) {
  sessionStorage.setItem(key, JSON.stringify(value))
}
