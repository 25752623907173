import { type ReactNode } from 'react'
import { type AlertColor } from '@mui/material'
import { create } from 'zustand'

import * as sessionStorage from '@core/utils/session-storage'

type Notification = {
  key: string
  message: ReactNode
  open: boolean
  severity: AlertColor
  checkboxKey?: string
}

type AddArgs = {
  message: Notification['message']
  severity?: Notification['severity']
  checkboxKey?: Notification['checkboxKey']
}

type State = {
  openKey: string
  items: Record<string, Notification>
}

type Actions = {
  add: (args: AddArgs) => Notification | null
  remove: (key: string) => void
  reset: () => void
}

const initialState: State = {
  openKey: '',
  items: {},
}

export const useSnackState = create<State & Actions>((set) => {
  const removeKey = (key: string) => {
    set((prevState) => {
      const copy = { ...prevState.items }
      delete copy[key]

      return { items: copy }
    })
  }

  return {
    ...initialState,

    add: ({ message, severity = 'success', checkboxKey = '' }) => {
      const key = `snack-${Date.now()}`
      const shouldSkip = sessionStorage.get(checkboxKey, false)
      if (shouldSkip) return null
      const notification = { message, severity, key, open: true, checkboxKey }

      set((prevState) => ({
        ...prevState,
        openKey: prevState.openKey || key,
        items: {
          ...prevState.items,
          [key]: notification,
        },
      }))

      return notification
    },

    remove: (key) => {
      set((prevState) => {
        const keys = Object.keys(prevState.items)
        const nextKey = keys[keys.indexOf(key) + 1] || ''

        // some time for the animation to finish
        setTimeout(() => removeKey(key), 500)

        return { openKey: nextKey }
      })
    },

    reset: () => {
      set(initialState)
    },
  }
})

export const addSnack = useSnackState.getState().add
