import React, { useMemo, useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  FormControlLabelProps,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import _ from 'lodash'

import { bffOptions } from '@core/api/bff'

import type { FormValues } from '../main-types'
import { AdminSettings } from './admin-settings-state'

const defaultValues: FormValues = {
  hasOptionRationale: false,
  features: {
    bff_enabled: {},
  },
}

export const AdminManageBFF = ({ adminSettings }: { adminSettings: AdminSettings }) => {
  const [loading, setLoading] = useState(false)
  const { handleClose, handleUpdateCustomer } = adminSettings

  const customer = adminSettings.customer!

  const { features, hasOptionRationale } = useMemo<FormValues>(() => {
    const result = _.pick(_.merge({}, defaultValues, _.cloneDeep(customer!)), [
      'features',
      'hasOptionRationale',
    ])

    return result
  }, [customer])

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const result: FormValues['features'] = _.cloneDeep(features)

    if (!result.bff_enabled) {
      result.bff_enabled = {}
    }

    result.bff_enabled[e.target.name] = e.target.checked

    setLoading(true)
    await handleUpdateCustomer({ features: result, hasOptionRationale })
    setLoading(false)
  }

  const getProps: (field: string) => FormControlLabelProps = (field) => {
    const checked = Boolean(features.bff_enabled?.[field])

    return {
      disabled: loading,
      onChange: handleChange,
      slotProps: { typography: { fontSize: 14 } },
      sx: { display: 'flex' },
      control: <Checkbox size="small" color="secondary" />,
      name: field,
      checked,
      label: field,
    }
  }

  return (
    <Box display="flex-col" gap={4}>
      <DialogContent sx={{ overflowY: 'auto' }}>
        <Box display="flex" gap={4} alignItems="center">
          <Box display="flex-col" justifyContent="" my={1}>
            <Typography variant="h6" gutterBottom className="">
              Enabled BFF Endpoints
            </Typography>
            <Box display="flex-col">
              {bffOptions.map((key) => (
                <FormControlLabel {...getProps(key)} key={key} />
              ))}
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <Divider sx={{ my: 2 }} />

      <DialogActions>
        <Button variant="contained" color="secondary" onClick={handleClose} disabled={loading}>
          Done
        </Button>
      </DialogActions>
    </Box>
  )
}
