import React from 'react'
import _ from 'lodash'

import { useUserRole } from '@containers/main/main-utils'
import { useSubscription } from '@core/api'

import ProjectEdit from './project-dialog-edit'
import ProjectSaveItem from './project-dialog-save-item'
import ProjectShare from './project-dialog-share'
import { GET_PROJECTS } from './projects-service'
import { useProjectsState } from './projects-state'

const SubscribeProjects = () => {
  const { role } = useUserRole()

  useSubscription(GET_PROJECTS, {
    context: { role },
    onData({ data }) {
      const projects = _.keyBy(data.data?.project || {}, 'id')

      useProjectsState.setState({ items: projects })
    },
  })

  return null
}

/*
  Wrap all project related components
*/
const ProjectsRoot = () => {
  return (
    <>
      <SubscribeProjects />
      <ProjectEdit />
      <ProjectSaveItem />
      <ProjectShare />
    </>
  )
}

export default ProjectsRoot
