import React from 'react'
import { Chip, type ChipProps } from '@mui/material'
import merge from 'lodash/merge'

import { tag as colors } from '@core/styles/colors'

export type TagColor = keyof typeof colors

export type CustomTagProps = ChipProps & {
  tagColor: TagColor
  selected?: boolean
  sxOverride?: ChipProps['sx']
}

export const CustomTag = React.forwardRef<HTMLDivElement, CustomTagProps>((props, ref) => {
  const { tagColor, selected, sxOverride, ...rest } = props

  const styles: ChipProps['sx'] = merge(
    {
      bgcolor: `tag.${tagColor}.main`,
      color: `tag.${tagColor}.contrastText`,
      borderRadius: 0.75,
      py: 0,
      px: 1,
      fontSize: '0.875rem',
      lineHeight: '1rem',
      height: 20,
      opacity: selected === undefined || selected ? '1' : '0.5',
      '&.MuiChip-clickable': {
        '&:hover, &:focus': {
          bgcolor: `tag.${tagColor}.dark`,
        },
      },
      '& .MuiChip-avatar': {
        bgcolor: `tag.${tagColor}.avatarBg`,
      },
    },
    sxOverride,
  )

  return <Chip {...rest} ref={ref} sx={styles} />
})

CustomTag.displayName = 'CustomTag'

export default CustomTag
