export const USER_AUTH_TOKEN = 'user-auth-token'
export const USER_REFRESH_TOKEN = 'user-refresh-token'
export const USER_PROFILE = 'user-profile-data'

export const JWT_CLAIMS = 'https://generate.finetunelearning.com/jwt/claims'

export const FILTER_TYPES = {
  tag: 'tag',
  topic: 'topic',
  all: 'ALL',
  clone: 'CLONE',
}

export const TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone

const DAY_SECONDS = 60 * 60 * 24
export const FIVE_DAYS_SECONDS = DAY_SECONDS * 5
export const MONTH_SECONDS = DAY_SECONDS * 30
export const SUCCESS = 'success'
export const ERROR = 'error'

// localStorage key name to record the user's login time
export const USER_LOGIN_TIME = 'ftg_userlogintime'
