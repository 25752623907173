import ErrorHandler from '@core/api/ErrorHandler'
import { addSnack } from '@core/snack/snack-state'
import { createAsyncThunk } from '@reduxjs/toolkit'

import * as queries from './users-queries'
import type { RawPermission } from './users-queries'
import type { Permission } from './users-types'

const context = { headers: { 'x-hasura-role': 'customer_admin' } }

interface InsertPermissionParams {
  items: RawPermission[]
}

export const setModelPermission = createAsyncThunk<Permission[], InsertPermissionParams>(
  'admin/setModelPermissions',
  async (variables, { extra }) => {
    try {
      const { data } = await extra.client.mutate({
        fetchPolicy: 'network-only',
        mutation: queries.UPSERT_AI_MODEL_PERMISSIONS,
        context,
        variables,
      })

      return data!.result.returning
    } catch (error) {
      const { message } = ErrorHandler(error) as any
      addSnack({ severity: 'error', message })
      throw error
    }
  },
)
