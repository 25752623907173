export const MCQ = 'MCQ'
export const FRQ = 'FRQ'
export const LIKERT = 'LIKERT'
export const MPFRQ = 'MPFRQ'
export const STORY = 'STORY'
export const CLOZE = 'CLOZE'
export const ITEM_SET = 'ITEM_SET'
export const ITEM_SET2 = 'ITEM_SET2'
export const PASSAGE_BUILDER = 'PASSAGE_BUILDER'
export const HOT_TEXT = 'HOT_TEXT'

export const subTitles = {
  [MCQ]: {
    question: 'Item Stem',
    options: 'Options',
    passage: 'Stimulus',
  },
  [FRQ]: {
    question: 'Stimulus',
    options: 'Item Prompts',
    passage: 'Passage',
  },
  [MPFRQ]: {
    question: 'Stimulus',
    options: 'Item Prompts',
    passage: 'Passage',
  },
  [HOT_TEXT]: {
    question: 'Item Stem',
    options: 'Options',
    passage: 'Stimulus',
  },
  [LIKERT]: {
    question: 'Item Stem',
    options: 'Options',
    passage: 'Stimulus',
  },
}

const labels = {
  [ITEM_SET2]: {
    label: 'ITEM SET',
  },
  [ITEM_SET]: {
    label: 'CLOZE',
  },
}

export const getContentTypeLabel = (type = '') => {
  if (labels[type]) {
    return labels[type].label
  }

  return type.split('_').join(' ')
}

const optionsKeyMap = {
  [MCQ]: 'answers',
  [HOT_TEXT]: 'answers',
  [LIKERT]: 'options',
  [MPFRQ]: 'parts',
  [FRQ]: '',
}

// https://github.com/academicmerit/ftg/blob/dev/api/src/chalicelib/lib/content_types.py
export const getOptionsKey = (contentType: string) => {
  if (contentType in optionsKeyMap) {
    return optionsKeyMap[contentType]
  }

  console.warn('getOptionsKey: unknown content type', contentType)

  return 'answers'
}
