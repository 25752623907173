import React from 'react'

import ExportResultDialog from './export-result-dialog'
import ExportStatusDialog from './export-status-dialog'

export default function ExportItemsRoot() {
  return (
    <>
      <ExportResultDialog />
      <ExportStatusDialog />
    </>
  )
}
