import React from 'react'
import { Badge, SxProps, Tab, Tabs } from '@mui/material'
import { Link, useLocation } from 'react-router-dom'

import { actions } from '@containers/main/main-slice'
import { AUTHOR_ROUTE, DELIVER_ROUTE, USERS_ROUTE } from '@core/constants/routes'
import { useAppDispatch, useAppSelector } from '@core/store'

const tab: SxProps = {
  color: 'other.downriver',
  width: 96,
  height: 56,
}

const getCurrentActiveRoute = (pathname) => {
  if (pathname.startsWith(DELIVER_ROUTE)) {
    return DELIVER_ROUTE
  }
  if (pathname.startsWith(USERS_ROUTE)) {
    return USERS_ROUTE
  }
  return AUTHOR_ROUTE
}

const FineTuneTabs = (props: { onClear: () => void }) => {
  const location = useLocation()
  const dispatch = useAppDispatch()
  const value = getCurrentActiveRoute(location.pathname)
  const user = useAppSelector((state) => state.main.user)
  const downloadNotification = useAppSelector((state) => state.main.downloadNotification)

  if (!user || !user.role) {
    return null
  }

  return (
    <Tabs
      sx={(theme) => ({
        m: {
          sm: theme.spacing(0, 'auto', 0, 5),
          md: theme.spacing(0, 'auto', 0, 7),
          lg: theme.spacing(0, 'auto', 0, 10),
        },
      })}
      variant="standard"
      value={value}
      aria-label="nav tabs"
    >
      <Tab
        component={Link}
        sx={tab}
        id="nav-tab-1"
        label="Author"
        to={AUTHOR_ROUTE}
        onClick={props.onClear}
        value={AUTHOR_ROUTE}
        aria-controls="nav-tabpanel-1"
      />

      <Tab
        component={Link}
        sx={tab}
        id="nav-tab-3"
        label={
          <Badge color="secondary" variant="dot" invisible={!downloadNotification}>
            Deliver
          </Badge>
        }
        to={DELIVER_ROUTE}
        value={DELIVER_ROUTE}
        onClick={() => dispatch(actions.set({ downloadNotification: false }))}
        aria-controls="nav-tabpanel-3"
      />

      {user.role === 'Admin' && (
        <Tab
          component={Link}
          sx={tab}
          id="nav-tab-4"
          label="Users"
          to={USERS_ROUTE}
          value={USERS_ROUTE}
          aria-controls="nav-tabpanel-4"
        />
      )}
    </Tabs>
  )
}

export default FineTuneTabs
