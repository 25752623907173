import { minWords as minCount, maxWords as maxCount } from './text'

/**
 * This file contains checks and validation functions that are being used in
 * Flexible Input settings. (`ai_model.input_settings`)
 */

/**
 * Function to check whether text contains the minimum number of words.
 * The function can be used in the `text`, `textarea` and `list` components.
 * For list component, it will be applied for each element.
 * @param text
 * @param min
 * @returns boolean
 */

export const minWords = (text: string, min: number): boolean =>
  !(Array.isArray(text) ? text?.some((e) => !minCount(e, min)) : !minCount(text, min))

/**
 * Function to check whether text contains the maximum number of words.
 * The function can be used in the `text`, `textarea` and `list` components.
 * For list component, it will be applied for each element.
 * @param text
 * @param max
 * @returns
 */
export const maxWords = (text: string, max: number): boolean =>
  !(Array.isArray(text) ? text?.some((e) => !maxCount(e, max)) : !maxCount(text, max))

/**
 * Function to check the number of minimum elements required.
 * The function can be used in the `list` component.
 * @param elements
 * @param params
 * @returns
 */
export const minElements = (elements: string, params: number): boolean => elements?.length >= params

/**
 * Function to check the number of maximum elements required.
 * The function can be used in the `list` component.
 * @param elements
 * @param params
 * @returns
 */
export const maxElements = (elements: string, params: number): boolean => elements?.length <= params

export default { minWords, maxWords, minElements, maxElements }
