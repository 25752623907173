export const uniqueIds = new Map()

export const getUniqueId = (prefix: string) => {
  if (!uniqueIds.has(prefix)) {
    uniqueIds.set(prefix, Date.now())
  }

  const id = uniqueIds.get(prefix) + 1
  uniqueIds.set(prefix, id)

  return `${prefix}-${id}`
}
