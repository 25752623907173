import { gql } from '@apollo/client'

export const GET_PROJECTS = gql`
  query projects {
    projects: project(where: { can_deliver: { _eq: true } }, order_by: { created_at: desc }) {
      id
      name
      createdAt: created_at
      # ignore admin's
      access(where: { user: { role: { _neq: "Admin" } } }) {
        write
        download
        read
        user {
          email
          id
        }
      }
      stats
      ownerId: owner_id
      owner {
        id
        email
        role
      }
    }
  }
`

export const GET_DRAFTS = gql`
  query drafts($draftDate: timestamptz!, $userId: String!) {
    items: item_aggregate(
      where: {
        project_id: { _is_null: true }
        created_at: { _gte: $draftDate }
        job: { owner: { id: { _eq: $userId } }, status: { _neq: DISCARDED } }
        ai_model: { quality_metrics: { _is_null: false }, root_id: { _is_null: true } }
        last_version: { quality_metrics: { _is_null: false, _neq: [] } }
        _not: {
          _or: [
            { last_version: { quality_metrics: { _eq: [] } } }
            { job: { ai_model: { quality_metrics: { _eq: [] } } } }
          ]
        }
        is_archived: { _neq: true }
      }
      order_by: { created_at: desc }
    ) {
      aggregate {
        count
      }
    }
  }
`

export const DELETE_PROJECTS = gql`
  mutation delete_project {
    delete_project(where: { can_deliver: { _eq: true } }) {
      affected_rows
    }
  }
`
