import { useApi } from '@core/api'
import { getClient } from '@core/api/api-state'
import { getUserRole, useBffEnabled } from '@core/main-state'
import { addSnack } from '@core/snack/snack-state'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { createPlaceholderJob, useExportJobs } from './export-items-state'
import * as queries from './export-queries'

export const exportItemsKeys = {
  all: ['export'] as const,
  list: ['export', 'list'] as const,
  details: (exportJobId: string) => ['export', 'detail', exportJobId] as const,
  exportItems: ['export', 'exportItems'] as const,
  archiveExportJobs: ['export', 'archiveExportJobs'] as const,
}

export const getLatestExports = async () => {
  const client = getClient()
  const { defaultRole, user } = getUserRole()

  const { data } = await client.query({
    query: queries.GET_LATEST_EXPORT_JOBS,
    context: { role: defaultRole },
    variables: { exportType: 'ProBuilder', userId: user.id },
    fetchPolicy: 'network-only',
  })

  return data
}

export const getExportDetails = async (exportJobId: string) => {
  const client = getClient()
  const { defaultRole } = getUserRole()

  const { data } = await client.query({
    query: queries.EXPORT_RESULT_QUERY,
    context: { role: defaultRole },
    variables: { exportJobId },
    fetchPolicy: 'network-only',
  })

  return data
}

export const useExportApi = () => {
  const api = useApi()
  const bffEnabled = useBffEnabled()
  const queryClient = useQueryClient()

  const [updateDialog, addExportJob, removeExportJob] = useExportJobs((state) => [
    state.updateDialog,
    state.add,
    state.remove,
  ])

  const exportItems = useMutation({
    mutationFn: async (variables: {
      exportType: string
      itemIds?: string[]
      projectIds?: string[]
    }) => {
      let jobId
      if (bffEnabled.exportItems) {
        const response = await api.exportItems({
          body: {
            export_type: variables.exportType,
            item_ids: variables.itemIds!,
            project_ids: variables.projectIds,
          },
        })
        jobId = response.data?.job_id
      } else {
        const client = getClient()
        const { defaultRole } = getUserRole()
        const { data } = await client.mutate({
          mutation: queries.EXPORT_ITEMS,
          context: { role: defaultRole },
          variables,
        })
        jobId = data?.export_items.job_id
      }
      if (!jobId) {
        console.error('no job id returned')
      }

      return jobId
    },
    mutationKey: exportItemsKeys.exportItems,
    async onSuccess(jobId, variables) {
      addExportJob(createPlaceholderJob(jobId, variables.exportType))
      queryClient.prefetchQuery({
        queryKey: exportItemsKeys.list,
      })
    },
  })

  const continueExport = useMutation({
    mutationKey: ['export', 'continueExport'] as const,
    mutationFn: async (variables: {
      exportJob: queries.ExportJobBasic
      mode: queries.ExportContinueMode
    }) => {
      let jobId
      if (bffEnabled.exportItems) {
        const response = await api.continueExportJob({
          path: { export_id: variables.exportJob.id },
          query: { mode: variables.mode },
        })
        jobId = response.data?.job_id
      } else {
        const client = getClient()
        const { defaultRole } = getUserRole()
        const { data } = await client.mutate({
          mutation: queries.CONTINUE_EXPORT,
          context: { role: defaultRole },
          variables: {
            exportJobId: variables.exportJob.id,
            mode: variables.mode,
          },
        })
        jobId = data?.continue_export_job.job_id
      }
      return jobId
    },
    onError(error, variables) {
      console.error(error, variables)
      addSnack({
        message: 'Error while continuing export job',
        severity: 'error',
      })
    },
    async onSuccess(jobId, variables) {
      await queryClient.refetchQueries({
        queryKey: exportItemsKeys.list,
      })

      // remove the export job from the status list
      removeExportJob(variables.exportJob.id)

      if (variables.mode === 'cancel') {
        // do not select the next job
        updateDialog({ selectedJobId: '' })

        // small message just to be clear that something happened
        addSnack({
          message: 'Export job cancelled',
          severity: 'info',
        })
      } else {
        updateDialog({ selectedJobId: jobId })
        addExportJob(createPlaceholderJob(jobId, variables.exportJob.exportType))
      }
    },
  })

  return {
    exportItems,
    continueExport,
  }
}

export const archiveExportJobs = async (ids: Set<string>) => {
  const client = getClient()
  const { defaultRole } = getUserRole()

  const { data } = await client.mutate({
    mutation: queries.ARCHIVE_EXPORT_JOBS,
    context: { role: defaultRole },
    variables: { ids: Array.from(ids) },
  })

  return data!
}
