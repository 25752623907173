import AddModeratorIcon from '@mui/icons-material/AddModerator'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import ManageModelIcon from '@mui/icons-material/Article'
import CalculateIcon from '@mui/icons-material/Calculate'
import ManageCustomerIcon from '@mui/icons-material/GppGood'
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions'

export const adminViews = {
  ADD_CUSTOMER: {
    label: 'Add Customer',
    icon: AddModeratorIcon,
  },
  SWITCH_CUSTOMER: {
    label: 'Switch Customer',
    icon: AdminPanelSettingsIcon,
  },
  MANAGE_CUSTOMER: {
    label: 'Manage Customer',
    icon: ManageCustomerIcon,
  },
  MANAGE_MODELS: {
    label: 'Manage AI Models',
    icon: ManageModelIcon,
  },
  MANAGE_INTEGRATIONS: {
    label: 'Manage Integrations',
    icon: IntegrationInstructionsIcon,
  },
  MANAGE_EDITORS: {
    label: 'Manage Math',
    icon: CalculateIcon,
  },
  MANAGE_BFF: {
    label: 'Manage V2',
    icon: IntegrationInstructionsIcon,
  },
}

export type AdminView = keyof typeof adminViews

type View = {
  key: AdminView
  label: string
  icon: typeof AddModeratorIcon
}

export const adminViewOptions: View[] = Object.entries(adminViews).map(([key, value]) => {
  return {
    label: value.label,
    key: key as AdminView,
    icon: value.icon,
  }
})
