import React from 'react'
import { Box, StyleProps } from '@mui/material'
import Typography from '@mui/material/Typography'
import { Link, useNavigate } from 'react-router-dom'

import ErrorHandler from '@core/api/ErrorHandler'
import Logo from '@core/components/Logo'

const styles = {
  error: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100vh',
    '& img': { mb: 3 },
  },
  '& h3': {
    mb: 2,
  },
  '& p': {
    mb: 2,
  },
} satisfies StyleProps

type CustomErrorProps = {
  title: string
  redirectTo?: string
  redirectToMessage?: string
  error?: object
  altMessage?: string
}

const CustomError = ({
  title,
  redirectTo,
  redirectToMessage,
  error,
  altMessage,
}: CustomErrorProps) => {
  const navigate = useNavigate()

  const { message, errorMessage: originalErrorMessage } = ErrorHandler(error || {})
  let errorMessage = message || 'Unknown error'
  if (message?.includes('This object was deleted')) {
    errorMessage = `The requested item no longer exists.`
  }
  if (originalErrorMessage?.includes('Login required')) {
    navigate('/')
  }
  return (
    <Box sx={styles.error}>
      <Logo />
      {(title && <Typography variant="h3">{title}</Typography>) || (
        <Typography variant="h3">{errorMessage}</Typography>
      )}
      {(altMessage && <p>{altMessage}</p>) ||
        (redirectTo && redirectToMessage && (
          <p>
            Please go back to <Link to={redirectTo}>{redirectToMessage}</Link>.
          </p>
        )) || (
          <p>
            Please click <Link to="/">here</Link> to continue.
          </p>
        )}
    </Box>
  )
}

const MemoCustomError = React.memo(CustomError)

MemoCustomError.displayName = 'Error'

export default MemoCustomError
