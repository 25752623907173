import React, { useState } from 'react'
import SearchIcon from '@mui/icons-material/Search'
import { LoadingButton } from '@mui/lab'
import {
  alpha,
  Box,
  Button,
  Chip,
  CircularProgress,
  DialogContent,
  Divider,
  Input,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material'
import _ from 'lodash'
import { useForm, type UseFormReturn } from 'react-hook-form'

import { useApi } from '@core/api'
import Checkbox from '@core/components/form/checkbox'
import { getContentTypeLabel } from '@core/constants/content-type'
import { useMainState } from '@core/main-state'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { FeaturesByModel } from '../main-types'

export type AiModel = {
  id: string
  internalModelName: string
  type: string
  name: string
  createdAt: string
  isInternal: boolean
}

export type OnSelect = (model: AiModel) => Promise<void>

type SearchBarProps = {
  onChange: (value: string) => void
  disabled: boolean
}

const SearchBar = (props: SearchBarProps) => {
  return (
    <Box component="form" noValidate autoComplete="off" aria-label="Search Users">
      <Input
        id="search-users"
        size="medium"
        disabled={props.disabled}
        sx={{
          px: 1,
          bgcolor: '#ebecf0',
          borderRadius: 0.75,
          '& fieldset': { border: 'none' },
        }}
        inputProps={{
          'aria-label': 'Search Users',
        }}
        placeholder="Search Models"
        endAdornment={
          <InputAdornment position="start" sx={{ opacity: props.disabled ? 0.5 : 1 }}>
            <SearchIcon />
          </InputAdornment>
        }
        onChange={(e) => props.onChange(e.target.value)}
      />
    </Box>
  )
}

type FormValues = FeaturesByModel & { is_internal: boolean }

const defaultValues: FormValues = {
  is_internal: false,
  editor_images: false,
  editor_desmos: false,
  editor_math_all: false,
  editor_math_finance: false,
  editor_wolfram: false,
}

type AiModelFeaturesFormProps = {
  form: UseFormReturn<FormValues>
  onSubmit: (data: FormValues) => void
  onCancel: () => void
}

const AiModelFeaturesForm = (props: AiModelFeaturesFormProps) => {
  const { form } = props

  return (
    <Box component="form" onSubmit={form.handleSubmit(props.onSubmit)}>
      <Divider>
        <Typography variant="caption">General</Typography>
      </Divider>
      <Box>
        <Checkbox
          name="is_internal"
          label="Internal"
          control={form.control}
          disabled={form.formState.isSubmitting}
        />

        <Box typography="caption">Make model available only to internal admin users</Box>
      </Box>
      <Divider>
        <Typography variant="caption">Editor</Typography>
      </Divider>
      <Box>
        <Checkbox
          name="editor_images"
          label="Images (Upload)"
          control={form.control}
          disabled={form.formState.isSubmitting}
        />
      </Box>
      <Box>
        <Checkbox
          name="editor_wolfram"
          label="Wolfram"
          control={form.control}
          disabled={form.formState.isSubmitting}
        />
      </Box>
      <Box>
        <Checkbox
          name="editor_math_all"
          label="Math All"
          control={form.control}
          disabled={form.formState.isSubmitting}
        />
      </Box>
      <Box>
        <Checkbox
          name="editor_math_finance"
          label="Math Finance"
          control={form.control}
          disabled={form.formState.isSubmitting}
        />
      </Box>
      <Box>
        <Checkbox
          name="editor_desmos"
          label="Desmos"
          control={form.control}
          disabled={form.formState.isSubmitting}
        />
      </Box>

      <Box mt={4} display="flex" gap={1}>
        <Button onClick={() => props.onCancel()} size="small">
          Cancel
        </Button>
        <Box flexGrow={1} />
        <Button
          disabled={!form.formState.isDirty || form.formState.isSubmitting}
          onClick={() => form.reset()}
          size="small"
        >
          Reset
        </Button>
        <LoadingButton
          loading={form.formState.isSubmitting}
          disabled={!form.formState.isDirty}
          type="submit"
          variant="contained"
          size="small"
        >
          Update
        </LoadingButton>
      </Box>
    </Box>
  )
}

const getDefaultIds = (): string[] => []

const defaultStats = {
  is_internal: {
    label: 'Internal',
    emoji: '🏠',
    ids: getDefaultIds(),
  },
  editor_math_all: {
    label: 'Math All',
    emoji: '🧮',
    ids: getDefaultIds(),
  },
  editor_math_finance: {
    label: 'Math Finance',
    emoji: '💵',
    ids: getDefaultIds(),
  },
  editor_images: {
    label: 'Images (Upload)',
    emoji: '🖼️',
    ids: getDefaultIds(),
  },
  editor_wolfram: {
    label: 'Wolfram',
    emoji: '🐴',
    ids: getDefaultIds(),
  },
  editor_desmos: {
    label: 'Desmos',
    emoji: '🧮',
    ids: getDefaultIds(),
  },
}

type MatchKey = keyof typeof defaultStats

type State = {
  stats: typeof defaultStats
  byModel: Record<
    string,
    {
      defaultValues: FormValues
      matches: MatchKey[]
    }
  >
}

type DisplayLabelsProps = State & {
  onChange: (value: MatchKey | '') => void
  selected: MatchKey | ''
  disabled: boolean
}

const DisplayLabels = (props: DisplayLabelsProps) => {
  return (
    <Box my={2} display="inline-flex" gap={1} flexWrap="wrap">
      {Object.entries(props.stats).map(([key, value]) => (
        <Chip
          size="small"
          key={key}
          disabled={props.disabled}
          sx={{ opacity: props.selected === key || !props.selected ? 1 : 0.5 }}
          label={`${value.label}: ${value.emoji} (${value.ids.length})`}
          onClick={() => props.onChange(props.selected === key ? '' : (key as MatchKey))}
        />
      ))}
    </Box>
  )
}

type ModelRowProps = {
  aiModel: AiModel
  editButton?: React.ReactNode
} & State

const DisplayAiModelEmojis = (props: ModelRowProps) => {
  return (
    <Box display="flex" gap={1}>
      {props.byModel[props.aiModel.internalModelName].matches.map((key) => (
        <Box key={key} title={props.stats[key].label}>
          {props.stats[key].emoji}
        </Box>
      ))}
    </Box>
  )
}

const ModelRow = (props: ModelRowProps) => {
  return (
    <ListItem sx={{ maxWidth: '100%' }} secondaryAction={props.editButton}>
      <ListItemText
        sx={{ px: 1, flexShrink: 0, width: `calc(100% - 80px)` }}
        primary={
          <>
            <DisplayAiModelEmojis {...props} />
            {props.aiModel.name}
          </>
        }
        primaryTypographyProps={{ noWrap: true }}
        secondaryTypographyProps={{ noWrap: true, component: 'div' }}
        secondary={
          <>
            <Typography variant="body2" color="primary.light" sx={{ mt: 1 }}>
              <b>Type: </b> {getContentTypeLabel(props.aiModel.type)}
            </Typography>
            <Typography variant="body2" noWrap color="primary.light">
              <b>Internal Name: </b>
              {props.aiModel.internalModelName}
            </Typography>
            <Typography variant="body2" noWrap color="primary.light">
              <b>Created at: </b>
              {new Date(props.aiModel.createdAt).toLocaleString()}
            </Typography>
          </>
        }
      />
    </ListItem>
  )
}

type Filter = {
  query: string
  match: MatchKey | ''
}

export const AdminManageModels = () => {
  const api = useApi()
  const [filter, setFilter] = useState<Filter>({ query: '', match: '' })
  const [selected, setSelected] = useState<AiModel | null>(null)
  const form = useForm({ defaultValues })
  const queryClient = useQueryClient()

  const featuresByModel = useMainState((_state) => _state.customer?.featuresByModel)

  const aiModelsQuery = useQuery({
    queryKey: ['ai-models'],
    queryFn: async () => {
      const { data } = await api.listAiModels({ throwOnError: true })
      return data as unknown as AiModel[]
    },
  })

  const updateMutation = useMutation({
    mutationKey: ['update-ai-model'],
    mutationFn: async (body: FormValues) => {
      if (!selected) {
        console.error('missing selected model to update')
        return
      }

      await api.updateAiModelFlags({
        throwOnError: true,
        path: {
          internal_name: selected.internalModelName,
        },
        body,
      })

      queryClient.refetchQueries({
        queryKey: ['ai-models'],
      })

      setSelected(null)
    },
  })

  const modelsState = React.useMemo<State>(() => {
    if (aiModelsQuery.data?.length === 0) {
      return { stats: defaultStats, byModel: {} }
    }

    const stats = _.cloneDeep(defaultStats)
    const byModel: State['byModel'] = {}

    aiModelsQuery.data?.forEach((model) => {
      const matches: MatchKey[] = []
      const prev = featuresByModel?.[model.internalModelName]
      const values: FormValues = {
        editor_images: prev?.editor_images ?? false,
        editor_wolfram: prev?.editor_wolfram ?? false,
        editor_math_all: prev?.editor_math_all ?? false,
        editor_math_finance: prev?.editor_math_finance ?? false,
        editor_desmos: prev?.editor_desmos ?? false,
        is_internal: model.isInternal,
      }

      if (values.is_internal) {
        stats.is_internal.ids.push(model.id)
        matches.push('is_internal')
      }

      if (values.editor_images) {
        stats.editor_images.ids.push(model.id)
        matches.push('editor_images')
      }

      if (values.editor_math_all) {
        stats.editor_math_all.ids.push(model.id)
        matches.push('editor_math_all')
      }

      if (values.editor_math_finance) {
        stats.editor_math_finance.ids.push(model.id)
        matches.push('editor_math_finance')
      }

      if (values.editor_desmos) {
        stats.editor_desmos.ids.push(model.id)
        matches.push('editor_desmos')
      }

      if (values.editor_wolfram) {
        stats.editor_wolfram.ids.push(model.id)
        matches.push('editor_wolfram')
      }

      if (byModel[model.internalModelName]) {
        console.warn(`Duplicate model name: ${model.internalModelName}`)
      }

      byModel[model.internalModelName] = { defaultValues: values, matches }
    })

    return { stats, byModel }
  }, [aiModelsQuery.data, featuresByModel])

  const filteredAiModels = React.useMemo(() => {
    return aiModelsQuery.data?.filter((model) => {
      const search = filter.query.toLowerCase().trim()

      if (
        filter.match &&
        !modelsState.byModel[model.internalModelName].matches.includes(filter.match)
      ) {
        return false
      }

      return (
        model.name.toLowerCase().includes(search) ||
        getContentTypeLabel(model.type).toLowerCase().includes(search) ||
        model.internalModelName.toLowerCase().includes(search)
      )
    })
  }, [aiModelsQuery.data, filter, modelsState])

  return (
    <>
      <DialogContent sx={{ overflowY: 'auto' }}>
        <Box>
          <SearchBar
            disabled={!!selected}
            onChange={(newValue) => setFilter((prev) => ({ ...prev, query: newValue }))}
          />

          <DisplayLabels
            {...modelsState}
            onChange={(newValue) => setFilter((prev) => ({ ...prev, match: newValue }))}
            selected={filter.match}
            disabled={!!selected}
          />
        </Box>
      </DialogContent>

      <List
        sx={{
          overflowY: selected ? 'hidden' : 'auto',
          mb: 3,
          height: '50vh',
          position: 'relative',
          p: 0,
        }}
      >
        {!!selected && (
          <Box
            px={3}
            sx={{
              position: 'sticky',
              width: '100%',
              height: '50vh',
              overflowY: 'auto',
              top: 0,
              left: 0,
              bgcolor: 'background.paper',
              zIndex: 200,
            }}
          >
            {selected && <ModelRow aiModel={selected} {...modelsState} />}

            <AiModelFeaturesForm
              form={form}
              onSubmit={(values) => updateMutation.mutateAsync(values)}
              onCancel={() => setSelected(null)}
            />
          </Box>
        )}

        {aiModelsQuery.isLoading && (
          <Box display="flex" justifyContent="center" alignItems="center" height={300} width="100%">
            <CircularProgress />
          </Box>
        )}

        {aiModelsQuery.data?.length === 0 && <Box mx={4}>No models found</Box>}

        {aiModelsQuery.data?.length && filteredAiModels?.length === 0 && (
          <Box mx={4}>No matching models found for these filters.</Box>
        )}

        {filteredAiModels?.map((model) => (
          <Box
            key={model.id}
            data-testid="permission-row"
            sx={{
              '&: nth-of-type(odd)': {
                background: (theme) => alpha(theme.palette.other.downriver, 0.03),
              },
            }}
          >
            <Box p={1} py={2}>
              <ModelRow
                aiModel={model}
                {...modelsState}
                editButton={
                  <Button
                    onClick={() => {
                      form.reset(modelsState.byModel[model.internalModelName].defaultValues)
                      setSelected(model)
                    }}
                    variant="contained"
                    color="tertiary"
                    size="small"
                    disabled={!!selected}
                    sx={{ mx: 3 }}
                  >
                    Edit AI Model Flags
                  </Button>
                }
              />
            </Box>
          </Box>
        ))}
      </List>
    </>
  )
}
