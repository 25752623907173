import type { State } from '@containers/comments/comments-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export function getInitialState(): State {
  return {
    itemId: null,
    comments: [],
  }
}

const JOB_FEEDBACK_STEP_KEY = 'cf-'

export const slice = createSlice({
  name: 'comments',
  initialState: getInitialState(),
  reducers: {
    set(state: State, action: PayloadAction<Partial<State>>) {
      Object.assign(state, action.payload)
    },
    setComments(state: State, action: PayloadAction<any>) {
      if (action.payload.comments) {
        const comments = action.payload.comments.map((comment, i) => {
          return {
            id: `${JOB_FEEDBACK_STEP_KEY}${i}`,
            ...comment,
          }
        })
        state.comments = comments
      }
    },
    createComment(state: State) {
      state.comments = [
        ...state.comments,
        {
          id: `comment-${Date.now()}`,
          name: '',
          value: '',
        },
      ]
    },
    updateComment(
      state: State,
      action: PayloadAction<{ index: number; field: string; value: string }>,
    ) {
      state.comments[action.payload.index][action.payload.field] = action.payload.value
    },
    deleteComment(state: State, action: PayloadAction<number>) {
      state.comments.splice(action.payload, 1)
    },
    resetState(state: State) {
      Object.assign(state, getInitialState())
    },
  },
})

export const { actions } = slice
