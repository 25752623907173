import React, { memo, useEffect } from 'react'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { useForm } from 'react-hook-form'

import { TextField, TextFieldProps } from '@core/components/textfield/textfield'

import { ProjectDetails } from './project-details'
import * as service from './projects-service'
import { useProjectsState } from './projects-state'

type Values = {
  name: string
}

const defaultValues: Values = {
  name: '',
}

type ProjectEditContentProps = {
  form: ReturnType<typeof useForm<Values>>
  onSubmit: (values: Values) => Promise<void>
}

const ProjectEditContent = (props: ProjectEditContentProps) => {
  const { mode, project } = useProjectsState((state) => state.edit)
  const onCloseProjectEdit = useProjectsState((state) => state.onCloseProjectEdit)

  const { handleSubmit, formState, register } = props.form

  const getProps = (field: keyof Values): TextFieldProps => {
    const error = formState.errors[field]
    let message = error?.message

    if (error?.type === 'maxLength') {
      message = 'Max length of 128'
    } else if (error?.type === 'minLength') {
      message = 'Min length of 3'
    }

    return {
      error: Boolean(error),
      helperText: message,
    }
  }

  const actionName = mode === 'create' ? 'Create' : 'Update'

  return (
    <>
      <DialogTitle>
        <Box typography="h3" component="span">
          {actionName} Folder
        </Box>
      </DialogTitle>
      <Box component="form" onSubmit={handleSubmit(props.onSubmit)}>
        <DialogContent sx={{ overflowY: 'initial', pt: mode === 'create' ? 2 : 0 }}>
          <ProjectDetails project={project} />

          <TextField
            autoFocus
            required
            {...register('name', {
              maxLength: 128,
              minLength: 3,
              required: 'Required',
            })}
            {...getProps('name')}
            disabled={formState.isSubmitting}
            label="Folder Title"
            aria-label="Folder Title"
            fullWidth
          />
        </DialogContent>

        <DialogActions sx={{ mt: 2 }}>
          <Button onClick={onCloseProjectEdit} color="secondary" disabled={formState.isSubmitting}>
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            color="secondary"
            type="submit"
            disabled={formState.isSubmitting || !formState.isDirty}
            loading={formState.isSubmitting}
          >
            {actionName}
          </LoadingButton>
        </DialogActions>
      </Box>
    </>
  )
}

export const ProjectEdit = () => {
  const { open, mode, project, onUpdate } = useProjectsState((state) => state.edit)
  const onCloseProjectEdit = useProjectsState((state) => state.onCloseProjectEdit)

  const form = useForm({ defaultValues })
  const { formState } = form

  useEffect(() => {
    if (!open) return

    let name = project?.name || ''

    if (mode === 'create') {
      name = ''
    }

    form.reset({ name })
  }, [project?.name, mode, open])

  const onSubmit = async (values: Values) => {
    const result =
      mode === 'create'
        ? await service.createProject(values.name)
        : await service.updateProject({ id: project!.id, name: values.name })

    if (result.success) {
      if (onUpdate) {
        onUpdate(result.project)
      }

      onCloseProjectEdit()
    } else {
      form.setError('name', { message: result.reason })
    }
  }

  return (
    <Dialog
      open={open}
      onClose={formState.isSubmitting ? undefined : onCloseProjectEdit}
      maxWidth="xs"
      fullWidth
      sx={{ '& .MuiPaper-root': { maxWidth: 480, px: 1.5, py: 2 } }}
    >
      <ProjectEditContent form={form} onSubmit={onSubmit} />
    </Dialog>
  )
}

export default memo(ProjectEdit)
