import React from 'react'
import { Close } from '@mui/icons-material'
import { Badge, Box, Dialog, IconButton, Tab, Tabs, type StyleProps } from '@mui/material'

import { useSubscription } from '@core/api'
import { useUserRole } from '@core/main-state'
import { useQuery, useQueryClient } from '@tanstack/react-query'

import * as exportAPI from './export-api'
import { useExportJobs } from './export-items-state'
import * as queries from './export-queries'
import IssuesTab from './export-result-issues'
import StatusTab from './export-result-status'

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    minHeight: 600,
  },
  sidebar: {
    width: 300,
    flexGrow: 1,
    bgcolor: '#fafbfb',
    py: 2,
  },
  content: {
    py: 2,
    bgcolor: '#fff',
    width: '100%',
  },
  tab: {
    px: 4,
    justifyContent: 'space-between',
    minHeight: 40,
    color: 'primary.dark',
    '&.Mui-selected': { bgcolor: '#f4f5f7', color: 'primary.dark' },
  },
  closeButton: {
    position: 'absolute',
    top: 8,
    right: 8,
    zIndex: 1,
  },
} satisfies StyleProps

const ExportSidebar = () => {
  const [updateDialog, tab, selectedJobId] = useExportJobs((state) => [
    state.updateDialog,
    state.exportDialog.tab,
    state.exportDialog.selectedJobId,
  ])

  const latestExports = useQuery({
    queryKey: exportAPI.exportItemsKeys.list,
    queryFn: exportAPI.getLatestExports,
  })

  const issuesCountQuery = useQuery({
    queryKey: exportAPI.exportItemsKeys.details(selectedJobId!),
    queryFn: () => exportAPI.getExportDetails(selectedJobId!),
    enabled: !!selectedJobId,
    select: (data) => data.exportJob.failedItems.length,
  })

  const issuesCount = issuesCountQuery.data || 0

  return (
    <Box sx={styles.sidebar}>
      <Box typography="h4" mt={2} mb={4} ml={4}>
        Export
      </Box>
      <Tabs
        orientation="vertical"
        value={tab}
        onChange={(_, value) => updateDialog({ tab: value })}
      >
        <Tab
          value={0}
          iconPosition="start"
          sx={styles.tab}
          label={
            <>
              Status
              <Badge badgeContent={latestExports.data?.agg.aggregate.count} color="tertiary">
                {' '}
              </Badge>
            </>
          }
        />
        <Tab
          value={1}
          iconPosition="start"
          sx={styles.tab}
          label={
            <>
              Issues
              <Badge badgeContent={issuesCount} color={issuesCount > 0 ? 'error' : 'tertiary'}>
                {' '}
              </Badge>
            </>
          }
        />
      </Tabs>
    </Box>
  )
}

// Subscribe to export job details and update react-query cache
// This component will be overwritten as soon we have the new real time system
const SubscribeExportJob = () => {
  const { defaultRole } = useUserRole()
  const queryClient = useQueryClient()
  const [selectedJobId] = useExportJobs((state) => [state.exportDialog.selectedJobId])

  useSubscription(queries.EXPORT_RESULT, {
    variables: { exportJobId: selectedJobId! },
    context: { role: defaultRole },
    onData({ data }) {
      if (!data.data) {
        return
      }

      queryClient.setQueryData(exportAPI.exportItemsKeys.details(selectedJobId!), data.data)
    },
  })

  return null
}

const ExportResult = () => {
  const [selectedJobId, tab] = useExportJobs((state) => [
    state.exportDialog.selectedJobId,
    state.exportDialog.tab,
  ])

  return (
    <Box sx={styles.container}>
      <ExportSidebar />
      {selectedJobId && <SubscribeExportJob />}
      <Box sx={styles.content}>
        {tab === 0 && <StatusTab />}
        {tab === 1 && <IssuesTab />}
      </Box>
    </Box>
  )
}

const ExportResultDialog = () => {
  const [open, updateDialog] = useExportJobs((state) => [
    state.exportDialog.open,
    state.updateDialog,
  ])

  const handleClose = () => {
    updateDialog({ open: false, selectedJobId: null, selectedJobIds: new Set() })
  }

  return (
    <Dialog
      open={open}
      fullWidth
      scroll="body"
      maxWidth="lg"
      onClose={handleClose}
      keepMounted={false}
    >
      <Box sx={styles.closeButton}>
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </Box>
      <ExportResult />
    </Dialog>
  )
}

export default ExportResultDialog
