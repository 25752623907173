// Messages that are excluded from Sentry
const excludedMessages = [/login required/i, /Network error/i]

export default function ErrorHandler(error, extra?) {
  const errorMessage =
    (error.graphQLErrors && error.graphQLErrors[0] && error.graphQLErrors[0].message) ||
    (error.networkError && error.networkError.error_description) ||
    error.message ||
    'Unknown error'
  const message = 'Sorry, an error occurred. Please try again.'

  for (const excludedMessage of excludedMessages) {
    if (excludedMessage.test(errorMessage) || excludedMessage.test(error.message)) {
      return { message, errorMessage }
    }
  }

  console.error('ErrorHandler', error, message, extra)

  return { message, errorMessage }
}
