import { routeLeave } from '@containers/main/main-slice'
import * as thunkActions from '@containers/metadata/metadata-thunk'
import type { ExternalMetadataSetting, State } from '@containers/metadata/metadata-types'
import * as authorClozeThunkActions from '@pages/author-cloze/author-cloze-thunk'
import { authorClear } from '@pages/author/author-actions'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type InitialValues = {
  itemId: string
  originalSubmodels: string[]
  submodels: string[]
  externalMetadata: any
  isCloze: boolean
}

export function getInitialState(): State {
  return {
    submodels: [],
    itemId: null,
    isSaving: false,
    selectedSubmodels: [],
    originalSubmodels: [],
    externalMetadata: {},
    isCloze: false,
  }
}

export const slice = createSlice({
  name: 'submodels',
  initialState: getInitialState(),
  reducers: {
    resetState(state: State) {
      Object.assign(state, getInitialState())
    },
    set(state: State, action: PayloadAction<Partial<State>>) {
      Object.assign(state, action.payload)
    },
    setSubmodels(state: State, action: PayloadAction<string[]>) {
      state.submodels = action.payload
      state.selectedSubmodels = action.payload
    },
    resetMetadata(state: State) {
      state.submodels = state.originalSubmodels
      state.selectedSubmodels = state.originalSubmodels
    },
    setInitialValues(state: State, action: PayloadAction<InitialValues>) {
      const { submodels, originalSubmodels, itemId, externalMetadata } = action.payload
      state.submodels = submodels
      state.selectedSubmodels = submodels
      state.originalSubmodels = originalSubmodels
      state.itemId = itemId
      state.externalMetadata = externalMetadata
    },
    setOptions(
      state: State,
      action: PayloadAction<{
        name: string
        value: string
        externalMetadataSettings: ExternalMetadataSetting[]
      }>,
    ) {
      if (action.payload.externalMetadataSettings) {
        const { name, value, externalMetadataSettings } = action.payload
        const input = externalMetadataSettings.find((x) => x.name === name)
        const { externalMetadata } = state

        if (!input || !input?.options) return

        const selectedOption = Array.isArray(value)
          ? { options: input.options.filter((x) => value.includes(x.text)) }
          : input.options.find((x) => x.text === value)
        if (selectedOption)
          state.externalMetadata = {
            ...externalMetadata,
            [name]: { ...selectedOption, title: input.title },
          }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(thunkActions.saveSubmodels.pending, (state) => {
      state.isSaving = true
    })

    builder.addCase(authorClozeThunkActions.fetchItemSet.fulfilled, (state, action) => {
      if (action.payload.notFound || !action.payload.items) return

      state.isCloze = true
      const itemDone = Object.values(action.payload.items).filter(
        (item) => item.status === 'DONE' && item.originalContent?.submodels,
      )
      let submodels = new Set()
      const hasDiffSubmodels = itemDone.some((item) => {
        submodels = new Set([...item.currentContent.submodels, ...submodels])

        return (
          new Set([...item.currentContent.submodels, ...item.originalContent!.submodels]).size !==
          item.currentContent.submodels.length
        )
      })

      const { id } = action.payload.items.root
      const { externalMetadata } = action.payload.items.root.currentContent
      const lastSubmodels = action.payload.items.root.currentContent.submodels

      if (!hasDiffSubmodels && submodels.size === lastSubmodels?.length) {
        state.submodels = lastSubmodels
        state.selectedSubmodels = lastSubmodels
      }
      state.itemId = id
      state.externalMetadata = externalMetadata || {}
    })

    builder.addCase(thunkActions.saveSubmodels.fulfilled, (state, action) => {
      state.isSaving = false
      state.submodels = action.payload.selectedSubmodels
    })
    builder.addCase(thunkActions.saveSubmodels.rejected, (state) => {
      state.isSaving = false
    })
    builder.addCase(authorClear, (state: State) => {
      Object.assign(state, {
        ...getInitialState(),
      })
    })
    builder.addCase(routeLeave, (state: State, action) => {
      if (action.payload.path === '/author/cloze/:itemId') {
        Object.assign(state, getInitialState())
      }
    })
  },
})

export const { actions } = slice
