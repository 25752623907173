import React from 'react'
import { Box, Container } from '@mui/material'

import Header from '@core/components/Header'

const ERRORS = {
  USER_NOT_FOUND_DB: {
    message: (
      <span>
        Any items you have created have been saved, but we could not connect your account. Please
        check that you are using the correct email address for your account. If you continue to have
        problems, then please contact your Admin.
      </span>
    ),
  },
  USER_WITHOUT_CUSTOMER: {
    message: (
      <span>
        Any items you have created have been saved, but we could not connect your account due to a
        change to your access. If you feel that this is a mistake, then please contact your Admin.
      </span>
    ),
  },
  USER_NOT_FOUND_AUTH0: {
    message: <span>Unexpected error on account creation.</span>,
  },
  FATAL_ERROR: {
    message: <span>Unexpected error on our servers.</span>,
  },
}

export type ErrorCode = keyof typeof ERRORS

export default function UserError(props: { code: ErrorCode | string; email?: string }) {
  const { message } = props.code in ERRORS ? ERRORS[props.code] : { message: props.code }

  return (
    <>
      <Header />
      <Container sx={{ py: 2 }}>
        <Box mt={5}>
          <Box typography="body2" color="primary.light">
            <i>{props.email}</i>
          </Box>
          <Box typography="h2" my={2}>
            Unable to connect your account
          </Box>
          <Box typography="body1" sx={{ maxWidth: { xs: '100%', sm: '60%' } }}>
            {message}
          </Box>

          <Box typography="caption" my={5}>
            <b>{props.code}</b>
          </Box>
        </Box>
      </Container>
    </>
  )
}
